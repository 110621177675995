import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-long-arrow-back',
  templateUrl: './long-arrow-back.component.html',
  styleUrls: ['./long-arrow-back.component.scss']
})
export class LongArrowBackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
