import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-project-requests',
  templateUrl: './project-requests.component.html',
  styleUrls: ['./project-requests.component.scss'],
})
export class ProjectRequestsComponent implements OnInit {
  constructor(
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  goHome() {
    this.analytics.logEvent('visit-site-from-project-requests-page');

    document.getElementsByTagName('html')[0].style.scrollBehavior = 'auto';

    this.router.navigateByUrl('');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth';
  }

  ngOnInit(): void {
    // console.log('viewed-project-requests-page');
    this.analytics.logEvent('viewed-project-requests-page');

    document.getElementById('theme-toggler').classList.remove('visible');
  }

  // ngOnDestroy(): void {
  //   document.getElementById('theme-toggler').classList.add('visible');
  // }
}
