import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Location
} from '@angular/common'
import {
  updateFor
} from 'typescript';

@Component({
  selector: 'app-scoala-de-business',
  templateUrl: './scoala-de-business.component.html',
  styleUrls: ['./scoala-de-business.component.scss']
})
export class ScoalaDeBusinessComponent implements OnInit {
  isPrototypeVisible = true;
  isPrototypeTransitioning = false;

  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {
    document.getElementsByTagName("html")[0].classList.add("dark-blue-theme");
  }

  ngAfterViewInit(): void {}

  ngOnDestroy():void {
    document.getElementsByTagName("html")[0].classList.remove("dark-blue-theme");
  }

  goBack() {
    this.location.back()
  }

  goHome() {
    document.getElementsByTagName("html")[0].style.scrollBehavior = "auto";

    this.router.navigateByUrl('');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
    document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth";
  }

  preparePrototypeTransition() {
    document.getElementById('prototype-container').children[0]['style']['will-change'] = 'top, left, margin, transform';
  }
  endPrototypeTransition() {
    document.getElementById('prototype-container').children[0]['style']['will-change'] = 'auto';
  }

  openPrototype(): void {
    if (!this.isPrototypeTransitioning) {
      this.isPrototypeTransitioning = true;
      let prototypeContainer = document.getElementById("prototype-container");

      let prototypeBackdrop = document.getElementById("prototype-backdrop");

      let mobileFrame = prototypeContainer.children[0];
      // mobileFrame["style"].willChange = "top, left, margin, transform";

      prototypeBackdrop.classList.add("prototype-backdrop");
      prototypeContainer["style"].zIndex = "3";
      prototypeContainer.classList.remove("d-none");
      prototypeContainer.classList.remove("d-xl-block");
      prototypeContainer.classList.remove("position-relative");
      prototypeBackdrop.classList.add("active");

      // Add the close button
      let img = document.createElement("img");
      img.setAttribute('src', './../../../assets/icons/close-icon-thin.svg');
      img.setAttribute('alt', 'Close');
      img.setAttribute('title', 'Close');
      img.setAttribute('id', 'close-backdrop-button');
      img.classList.add("close-button");
      prototypeBackdrop.appendChild(img);

      // 
      // desiredPrototypeGhost creates a projection of where the frame will be placed.
      // 
      let desiredPrototypeGhost = document.createElement("div");
      desiredPrototypeGhost["style"].border = "none";
      desiredPrototypeGhost.classList.add("desktop-frame");
      desiredPrototypeGhost.classList.add("prototype-frame-focused");
      prototypeContainer.appendChild(desiredPrototypeGhost);

      let desiredPrototypePosition = desiredPrototypeGhost.getBoundingClientRect();
      let inlinePrototypePosition = mobileFrame.getBoundingClientRect();

      // 
      // inlinePrototypeGhost keeps the space occupied so that the content won't take the whole space in the background
      // 
      let inlinePrototypeGhost = document.createElement("div");
      inlinePrototypeGhost.setAttribute('id', 'inlinePrototypeGhost');
      inlinePrototypeGhost.classList.add("desktop-frame");
      inlinePrototypeGhost["style"].opacity = "0";
      // inlinePrototypeGhost.addEventListener('click', this.closePrototype.bind(this), { passive: true });
      inlinePrototypeGhost.classList.add("ml-5");
      inlinePrototypeGhost.classList.add("mt-5");
      prototypeContainer.appendChild(inlinePrototypeGhost);

      let scalingFactors = {
        top: (desiredPrototypePosition.top - inlinePrototypePosition.top) / inlinePrototypePosition.height * 100,
        left: (desiredPrototypePosition.left - inlinePrototypePosition.left) / inlinePrototypePosition.width * 100,
        height: (desiredPrototypePosition.height / inlinePrototypePosition.height) * 100,
        width: (desiredPrototypePosition.width / inlinePrototypePosition.width) * 100,
      }

      mobileFrame["style"] = {};
      mobileFrame["style"].opacity = "1";
      mobileFrame["style"].transformOrigin = "top left";
      mobileFrame["style"].margin = "0";
      mobileFrame["style"].transform = `translate(${scalingFactors.left}%, ${scalingFactors.top}%) scale(${scalingFactors.width}%, ${scalingFactors.height}%)`;
      prototypeContainer.removeChild(desiredPrototypeGhost);

      setTimeout(function () {
        prototypeContainer["style"].zIndex = "";
        mobileFrame["style"] = {};
        mobileFrame["style"].transition = "all 0s";
        mobileFrame.classList.remove("ml-5");
        mobileFrame.classList.remove("mt-5");
        mobileFrame.classList.add("prototype-frame-focused");
        this.isPrototypeTransitioning = false;
      }.bind(this, mobileFrame), 400);
    }
  }

  closePrototype(): void {
    if (!this.isPrototypeTransitioning) {
      this.isPrototypeTransitioning = true;
      let prototypeContainer = document.getElementById("prototype-container");
      let prototypeBackdrop = document.getElementById("prototype-backdrop");

      // console.log("closing", prototypeContainer);
      if (prototypeBackdrop.classList.contains("prototype-backdrop")) {
        let mobileFrame = prototypeContainer.children[0];

        prototypeBackdrop.classList.remove("active");
        // prototypeContainer.classList.add("d-none");
        prototypeContainer.classList.add("d-xl-block");
        prototypeContainer.classList.add("position-relative");

        if (!this.isPrototypeVisible) {
          this.hidePrototype();
        }

        setTimeout(function () {
          prototypeBackdrop.classList.remove("prototype-backdrop");
        }.bind(this), 400);

        // Remove close button
        document.getElementById("close-backdrop-button").remove();
        let desiredPrototypeGhost = document.getElementById("inlinePrototypeGhost");

        // let desiredPrototypeGhost = document.createElement("div");
        // desiredPrototypeGhost.classList.add("mobile-frame");
        // prototypeContainer.appendChild(desiredPrototypeGhost);

        let desiredPrototypePosition = desiredPrototypeGhost.getBoundingClientRect();
        let inlinePrototypePosition = mobileFrame.getBoundingClientRect();

        let scalingFactors = {
          top: (desiredPrototypePosition.top - inlinePrototypePosition.top) / inlinePrototypePosition.height * 100,
          left: (desiredPrototypePosition.left - inlinePrototypePosition.left) / inlinePrototypePosition.width * 100,
          height: (desiredPrototypePosition.height / inlinePrototypePosition.height) * 100,
          width: (desiredPrototypePosition.width / inlinePrototypePosition.width) * 100,
        }

        mobileFrame["style"] = {};
        mobileFrame["style"].top = inlinePrototypePosition.top + "px";
        mobileFrame["style"].left = inlinePrototypePosition.left + "px";
        mobileFrame["style"].opacity = "1";
        mobileFrame["style"].zIndex = "3";
        mobileFrame["style"].transformOrigin = "top left";
        mobileFrame["style"].margin = "0";
        mobileFrame["style"].transform = `translate(${scalingFactors.left}%, ${scalingFactors.top}%) scale(${scalingFactors.width}%, ${scalingFactors.height}%)`;


        setTimeout(function () {
          mobileFrame["style"] = {};
          mobileFrame["style"].transition = "all 0s";
          prototypeContainer.removeChild(desiredPrototypeGhost);
          mobileFrame.classList.add("ml-5");
          mobileFrame.classList.add("mt-5");
          mobileFrame.classList.remove("prototype-frame-focused");
          this.isPrototypeTransitioning = false;
          this.endPrototypeTransition();
        }.bind(this, mobileFrame), 400);
      }
    }
  }
  hidePrototype(): void {
    // console.log("hiding", prototypeContainer);
    let prototypeContainer = document.getElementById("prototype-container");

    this.isPrototypeVisible = false;
    prototypeContainer.classList.remove("d-xl-block");
    prototypeContainer.classList.add("d-none");
    document.getElementById("show-prototype-button").classList.remove("d-xl-none");
    document.getElementById("hide-prototype-button").classList.remove("d-xl-flex");
  }

  showPrototype(): void {
    // console.log("showing", prototypeContainer);
    let prototypeContainer = document.getElementById("prototype-container");

    if (window.innerWidth < 1200) {
      this.openPrototype();
    } else {
      this.isPrototypeVisible = true;
      prototypeContainer.classList.add("d-xl-block");
      prototypeContainer.classList.remove("d-none");
      document.getElementById("show-prototype-button").classList.add("d-xl-none");
      document.getElementById("hide-prototype-button").classList.add("d-xl-flex");
    }
  }
}
