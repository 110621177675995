import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-slide-close',
  templateUrl: './slide-close.component.html',
  styleUrls: ['./slide-close.component.scss']
})
export class SlideCloseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
