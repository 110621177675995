import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DragNDropComponent } from './drag-n-drop/drag-n-drop.component';
import { HomeComponent } from './home/home.component';
import { IzibacComponent } from './projects/izibac/izibac.component';
import { PetecoComponent } from './projects/peteco/peteco.component';
import { ScoalaDeBusinessComponent } from './projects/scoala-de-business/scoala-de-business.component';
import { CVComponent } from './side-quests/cv/cv.component';
import { MailSignatureComponent } from './side-quests/mail-signature/mail-signature.component';
import { ProjectRequestsComponent } from './side-quests/project-requests/project-requests.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'izibac', component: IzibacComponent },
  { path: 'peteco', component: PetecoComponent },
  { path: 'scoala-de-business', component: ScoalaDeBusinessComponent },
  // { path: 'drag-n-drop', component: DragNDropComponent },
  // { path: 'pad-jam', component: PadJamComponent },
  { path: 'cv', component: CVComponent },
  { path: 'i-want-project', component: ProjectRequestsComponent },
  { path: 'mail-signature', component: MailSignatureComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
