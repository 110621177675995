  <div id="rating-stars-container" class="row mt-5 sticky">
    <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mb-4 mb-lg-0" tabindex=0>
      <div class="subtitle pl-0 card-title color-primary text-center">
        {{ratingLabel}}
      </div>
      <div class="d-flex justify-content-center">
        <div class="rating-stars">
          <div *ngFor="let star of [1,2,3,4,5]" class="rating-star" (click)="rate(star)"
            [ngClass]="star <= rating ? 'active': '' ">
            <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.8644 3.03307C19.8657 -0.0486064 24.2255 -0.0486107 25.2268 3.03306L28.0301 11.6607C28.4778 13.0388 29.7621 13.9719 31.2112 13.9719H40.2828C43.5231 13.9719 44.8703 18.1183 42.2489 20.0229L34.9098 25.355C33.7375 26.2068 33.2469 27.7165 33.6947 29.0947L36.498 37.7223C37.4993 40.804 33.9722 43.3666 31.3508 41.462L24.0117 36.1298C22.8393 35.2781 21.2519 35.2781 20.0795 36.1298L12.7405 41.462C10.119 43.3666 6.59192 40.804 7.59322 37.7223L10.3965 29.0947C10.8443 27.7165 10.3537 26.2068 9.1814 25.355L1.84232 20.0229C-0.779103 18.1183 0.56813 13.9719 3.80839 13.9719H12.88C14.3291 13.9719 15.6134 13.0388 16.0612 11.6607L18.8644 3.03307Z"
                fill="#00A3C7" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>