<div id="scrollable-timeline-cards" class="timeline-scroll pt-3 pl-0 pl-sm-5 pr-0 pr-sm-5">
  <div *ngFor="let projectInfo of projects; let i = index" [id]="'timeline-project-card-'+i" class="timeline-glass-card mr-2 mr-sm-4" tabindex=0>
    <div style="position: absolute; top: 0; left: -20px;"[id]="'timeline-project-anchor-'+(projects.length - i -1)"></div>
    <div class="pl-3 pr-3 pt-3">
      <div class="d-flex justify-content-between align-items-center timeline-project-header">
        <div class="timeline-project-period">
          <span *ngIf="projectInfo.displayStartDate && projectInfo.displayEndDate">
            {{projectInfo.displayStartDate}} - {{projectInfo.displayEndDate}}
          </span>
          <span *ngIf="projectInfo.displayStartDate && !projectInfo.displayEndDate">
            {{projectInfo.displayStartDate}}
          </span>   
        </div>
        <a [href]="projectInfo.website" target="_blank" rel="noopener noreferrer" tabindex=-1 >
          <img class="timeline-project-logo" [src]="projectInfo.logoPath" [alt]="projectInfo.website" [title]="projectInfo.website" />
        </a>
      </div>
      <p class="timeline-project-description mt-3">
        {{projectInfo.description}}
      </p>
      <p *ngIf="projectInfo.overallRole">
        <b>Overall Role: </b>{{projectInfo.overallRole}}
      </p>
      <!-- <div class="timeline-project-subtitle">
        {{projectInfo.subprojectsLabel}}
      </div> -->
    </div>

    <div class="subprojects-container">
      <div *ngFor="let subproject of projectInfo.subprojects" class="subproject p-3 mt-1">
        <div class="d-flex justify-content-between align-items-center">
          <p class="timeline-project-subtitle">
            {{subproject.title}}
          </p>
          <div class="d-flex gap-1 justify-content-end">
            <div *ngIf="subproject.iframeURL" (click)="viewSubProject($event, subproject.iframeURL, subproject.iframeParameters)" class="animate-on-hover round-button" tabindex=0 title="Open prototype" >
              <icon-internal-fullscreen class="text-primary"></icon-internal-fullscreen>
            </div>
            <a class="no-decoration animate-on-hover round-button" *ngIf="subproject.projectURL" [href]="subproject.projectURL" tabindex=0 title="See more project details">
              <icon-external-link class="text-primary"></icon-external-link>
            </a>

            <!-- <div *ngIf="subproject.iframeURL" (click)="viewSubProject($event, subproject.iframeURL, subproject.iframeParameters)" class="action-button thin-action-button" tabindex=0>Prototype</div>
            <a class="no-decoration" *ngIf="subproject.projectURL" [href]="subproject.projectURL">
              <div class="action-button thin-action-button" tabindex=0>More</div>
            </a> -->
          </div>
        </div>
        <p>{{subproject.description}}</p>
        <p>
          <b>Role: </b>{{subproject.role}}
        </p>
        <p class="pb-0 mb-0">
          <b>Technologies: </b>{{subproject.technologies}}
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showLeftScrollControl" (mousedown)="startScroll($event, -1)" (mouseup)="stopScroll()"
  (mouseleave)="stopScroll()" class="scroll-control scroll-control-left d-none d-lg-block">
  <div class="scroll-control-visual">
    <icon-indicator-left></icon-indicator-left>
  </div>
</div>
<div [class]="showRightScrollControl ? 'visible' : 'hidden'" (mousedown)="startScroll($event, 1)" (mouseup)="stopScroll()" (mouseleave)="stopScroll()"
  class="scroll-control scroll-control-right d-none d-lg-flex">
  <div class="scroll-control-visual">
    <icon-indicator-right></icon-indicator-right>
  </div>
</div>
