<bg-shapes></bg-shapes>
<custom-cursor id="cursor-container"></custom-cursor>

<div class="container">
  <div id="menu/intro" class="menu" (click)="toggleMobileMenu($event)">
    <div class="menu-toggler animate-on-hover" tabindex="0">
      <icon-burger-menu></icon-burger-menu>
    </div>
    <a id="menu/projects" href="#projects" class="menu-item" tabindex="0">
      projects
    </a>
    <a id="menu/evolution" href="#evolution" class="menu-item" tabindex="0">
      evolution
    </a>
    <a id="menu/what-i-do" href="#what-i-do" class="menu-item" tabindex="0">
      what i do
    </a>
    <a id="menu/about" href="#about" class="menu-item" tabindex="0"> about </a>
  </div>
</div>

<div id="hero-container" class="hero-container">
  <div id="hero-trigger" class="bg bg-top"></div>
</div>

<div id="intro" class=" ">
  <div class="intro-container d-flex justify-content-center">
    <div class="message-container fade-after-intro">
      <div id="message-bubble" class="message-bubble">
        <div class="intro-headline">
          Hi <span style="white-space: nowrap"> there !</span>
        </div>
        <div class="intro-subtitle">
          I’m Eduard and I design
          <br />
          and build web apps
        </div>
      </div>
      <img
        id="avatar"
        class="avatar"
        loading="eager"
        src="/assets/people/me-small.png"
        title="Eduard Lache"
        alt="Eduard Lache"
      />
    </div>

    <!-- <object data="./assets/icons/scroll-arrow.svg" type="image/svg+xml">
        <img src="./assets/icons/scroll-arrow.svg" />
      </object> -->

    <!-- <svg width="41" height="89" viewBox="0 0 41 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5 0.5V87.5M20.5 87.5L39.5 59.5M20.5 87.5L1.5 59.5" stroke="white" stroke-width="3" stroke-linejoin="round"/>
        </svg> -->

    <img
      id="scroll-invitation-arrow"
      class="scroll-invitation"
      (click)="goToProjects()"
      src="./assets/icons/scroll-arrow.svg"
    />
  </div>
</div>

<div class="container intro-spacer home-content-container">
  <div id="projects" class="projects mt-6 pl-2 pl-sm-0 pr-2 pr-sm-0">
    <div class="title sticky">My work</div>

    <project-card
      id="nft-platform"
      [projectIndex]="-2"
      [projectTitle]="projects.nftPlatform.title"
      [projectImages]="projects.nftPlatform.pictures"
    >
      <div
        mobileContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          NFT Platform is a a concept of my own creation and designed with a
          touch of elegance and a dash of the extraordinary. This platform is
          not just a marketplace but a realm where art, technology, and
          innovation intertwine to create a symphony of aesthetic and functional
          brilliance.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>UX, UI</div>
          <div>Product design</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some websites I worked with:</b>
          <div>Figma</div>
          <div>Spline 3D</div>
          <div>Unsplash</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>

      <div
        desktopContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          NFT Platform is a a concept of my own creation and designed with a
          touch of elegance and a dash of the extraordinary. This platform is
          not just a marketplace but a realm where art, technology, and
          innovation intertwine to create a symphony of aesthetic and functional
          brilliance.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>UX, UI</div>
          <div>Product design</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some websites I worked with:</b>
          <div>Figma</div>
          <div>Spline 3D</div>
          <div>Unsplash</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>
    </project-card>

    <project-card
      id="nft-platform"
      [projectIndex]="3"
      [projectTitle]="projects.findYourWhy.title"
      [projectImages]="projects.findYourWhy.pictures"
    >
      <div
        mobileContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          "Find Your Why" is a project I both designed and developed. It
          showcases an event that brings together esteemed entrepreneurs,
          business professionals, and influencers to discuss pivotal topics.
          Crafted to be a networking space, it enables meaningful interactions
          and the exchange of ideas among attendees.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>User Interface Design</div>
          <div>Senior React Developer</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some technologies I worked with:</b>
          <div>Figma</div>
          <div>React</div>
          <div>Typescript</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>

      <div
        desktopContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          "Find Your Why" is a project I both designed and developed. It
          showcases an event that brings together esteemed entrepreneurs,
          business professionals, and influencers to discuss pivotal topics.
          Crafted to be a networking space, it enables meaningful interactions
          and the exchange of ideas among attendees.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>User Interface Design</div>
          <div>Senior React Developer</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some technologies I worked with:</b>
          <div>Figma</div>
          <div>React</div>
          <div>Typescript</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>
    </project-card>

    <project-card
      id="izibac"
      [projectIndex]="0"
      [projectTitle]="projects.izibac.title"
      [projectImages]="projects.izibac.pictures"
    >
      <div
        mobileContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          iziBAC is a gamified mobile application that helps high-schoolers
          learn for the Baccalaureate exam in a gamified way.
        </p>
        <p class="article-text">
          It is a startup we raised from 0 to € 1,600,000 valuation.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>CTO & Co-Founder</div>
          <div>Product design, UI, UX</div>
          <div>Backend</div>
          <div>Infrastructure</div>
        </div>
        <br />
        <br />
        <div class="article-text">
          <b>Some skills I learned:</b>
          <div>UI & UX</div>
          <div>Firebase</div>
          <div>Business development</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>

      <div
        desktopContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          iziBAC is a gamified mobile application that helps high-schoolers
          learn for the Baccalaureate exam in a gamified way.
        </p>
        <p class="article-text">
          It is a startup we raised from 0 to € 1,600,000 valuation.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>CTO & Co-Founder</div>
          <div>Product design, UI, UX</div>
          <div>Backend</div>
          <div>Infrastructure</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some skills I learned:</b>
          <div>UI & UX</div>
          <div>Firebase</div>
          <div>Business development</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>
    </project-card>

    <project-card
      id="peteco"
      [projectIndex]="1"
      [projectTitle]="projects.peteco.title"
      [projectImages]="projects.peteco.pictures"
    >
      <div
        mobileContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          Peteco is the first social marketplace in the world designed for
          animal lovers!
        </p>
        <p class="article-text">
          I designed the e-commerce mobile app intended to to make shopping
          easier for animal lovers.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>UX & UI design</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some technologies I worked with:</b>
          <div>Figma</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>

      <div
        desktopContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          Peteco is the first social marketplace in the world designed for
          animal lovers!
        </p>
        <p class="article-text">
          I designed the e-commerce mobile app intended to make shopping easier
          for animal lovers.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>UX & UI design</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some technologies I worked with:</b>
          <div>Figma</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>
    </project-card>

    <project-card
      id="bcr"
      [projectIndex]="2"
      [projectTitle]="projects.bcr.title"
      [projectImages]="projects.bcr.pictures"
    >
      <div
        mobileContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          Școala de Business is an online web, gamified, learning platform. It
          was developed for BCR, the romanian subsidiary of Erste Group Bank,
          one of the largest financial services providers in Central and Eastern
          Europe.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>UX, UI, Product design</div>
          <div>Frontend development</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some technologies I worked with:</b>
          <div>HTML & CSS</div>
          <div>Javascript</div>
          <div>Figma</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>

      <div
        desktopContent
        class="d-flex flex-column h-100 justify-content-between"
      >
        <p class="article-text">
          Școala de Business is an online web, gamified, learning platform. It
          was developed for BCR, the romanian subsidiary of Erste Group Bank,
          one of the largest financial services providers in Central and Eastern
          Europe.
        </p>
        <br />
        <div class="article-text">
          <b>Role:</b>
          <div>UX, UI, Product design</div>
          <div>Frontend development</div>
        </div>
        <br />
        <div class="article-text">
          <b>Some technologies I worked with:</b>
          <div>HTML & CSS</div>
          <div>Javascript</div>
          <div>Figma</div>
        </div>
        <br />
        <!-- <button mat-flat-button color color="primary" class="action-button">View more</button> -->
        <!-- <div class="primary-gradient-button action-button" tabindex=0>View more</div> -->
      </div>
    </project-card>
  </div>

  <div id="evolution" class="evolution mt-6">
    <div class="title sticky-hidden pl-2 pl-sm-0 pr-2 pr-sm-0">Evolution</div>

    <div
      id="collapsable-chart"
      class="col-12 p-lg-5 mb-5 pl-2 pl-sm-0 pr-2 pr-sm-0"
    >
      <div class="timeline-container">
        <div
          *ngFor="let project of timelineProjects; let i = index"
          [id]="'timeline-project-graph-' + i"
          class="p-0 period-container"
          [ngClass]="project.name"
          [ngStyle]="project.style"
          (mouseover)="hoverTimeline($event)"
          (click)="clickTimeline($event)"
        >
          <img class="graph-curve" [src]="project.svgPath" />
          <div class="graph-base"></div>
        </div>
      </div>

      <div class="d-flex flex-nowrap timeline-axis">
        <div *ngFor="let year of years" class="year">
          {{ year }}
        </div>
      </div>

      <div
        class="expand-collapse-button animate-on-hover d-none d-lg-block"
        (click)="toggleCollapsingOfChart($event)"
      >
        <icon-indicator-up></icon-indicator-up>
      </div>

      <!-- <img src="./../../assets/icons/indicator-down.svg" class="p-3 expand-collapse-button expand-chart"
        (click)="collapseChart($event)" /> -->
    </div>

    <timeline-project-cards
      id="timeline-project-cards"
      [projects]="reversedTimelineProjects"
    >
    </timeline-project-cards>
  </div>

  <div id="what-i-do" class="what-i-do pl-2 pl-sm-0 pr-2 pr-sm-0">
    <div class="title sticky">What I do</div>
    <div class="glass-card mt-6 skill-category ux-bg" tabindex="0">
      <div class="subtitle card-title color-ux">UX - User Experience</div>
      <!-- <img class="skill-level-icon" src="./assets/icons/skill-levels/ux-skill.svg" /> -->
      <div id="ux-offer-1" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-ux col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>Analysis</p>
          <p>User Stories</p>
          <p>Information architecture</p>
          <p>Design workshop facilitation</p>
        </div>
        <div
          class="mb-5 col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <!-- <img loading="lazy" src="./assets/logos/sprint-book.png" alt="Sprint Book" title="Sprint Book" /> -->
          <img
            loading="lazy"
            style="max-width: 100%"
            src="./assets/logos/google-docs.png"
            alt="Google Docs"
            title="Google Docs"
          />
        </div>
      </div>
      <div id="ux-offer-2" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-ux col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>Wireframes</p>
          <p>User Flows</p>
        </div>
        <div
          class="col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/figma-white.png"
            alt="Figma"
            title="Figma"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/figma.png"
            alt="Figma"
            title="Figma"
          />
        </div>
      </div>
    </div>

    <div class="glass-card mt-6 skill-category ui-bg" tabindex="0">
      <div class="subtitle card-title color-ui">UI - User Interface</div>
      <!-- <img class="skill-level-icon" src="./assets/icons/skill-levels/ui-skill.svg" /> -->
      <div id="ui-offer-1" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-ui col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>Prototypes</p>
          <p>User Interface & Graphic Design</p>
          <p>Responsive design</p>
          <p>Interactions and 3D design</p>
        </div>
        <div
          class="mb-5 col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/figma-white.png"
            alt="Figma"
            title="Figma"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/figma.png"
            alt="Figma"
            title="Figma"
          />
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/spline-white.png"
            alt="Spline"
            title="Spline"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/spline.png"
            alt="Spline"
            title="Spline"
          />
        </div>
      </div>
      <div
        id="ui-offer-2"
        class="p-2 p-md-3 p-lg-4 mr-5 ml-5 row marker-outline"
      >
        <div
          class="offer-text color-ui col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>User feedback</p>
          <p>Analytics</p>
          <p>Heatmaps</p>
        </div>
        <div
          class="col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/maze-white.svg"
            height="50"
            alt="Maze.co"
            title="Maze.co"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/maze.svg"
            height="50"
            alt="Maze.co"
            title="Maze.co"
          />
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/google-analytics-white.png"
            alt="Analytics"
            title="Analytics"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/google-analytics.png"
            alt="Analytics"
            title="Analytics"
          />
        </div>
      </div>
    </div>

    <div class="glass-card mt-6 skill-category frontend-bg" tabindex="0">
      <div class="subtitle card-title color-frontend">Frontend</div>
      <!-- <img class="skill-level-icon" src="./assets/icons/skill-levels/frontend-skill.svg" /> -->
      <div id="fe-offer-1" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-frontend col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>Layouts & Components</p>
          <p>Responsiveness</p>
          <p>Gestures</p>
          <p>Transitions & Animations</p>
        </div>
        <div
          class="mb-5 col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <img
            loading="lazy"
            src="./assets/logos/html.png"
            alt="HTML"
            title="HTML"
          />
          <img
            loading="lazy"
            src="./assets/logos/scss.png"
            alt="SCSS"
            title="SCSS"
          />
          <img
            loading="lazy"
            src="./assets/logos/js.png"
            alt="Javascript"
            title="Javascript"
            style="border-radius: 10px"
          />
          <img
            loading="lazy"
            src="./assets/logos/ts.png"
            alt="Typescript"
            title="Typescript"
          />
        </div>
      </div>
      <div id="fe-offer-2" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-frontend col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>Frontend web</p>
          <p>Frontend mobile cross-platform</p>
        </div>
        <div
          class="col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <img
            loading="lazy"
            src="./assets/logos/angular.png"
            alt="Angular"
            title="Angular"
          />
          <img
            loading="lazy"
            src="./assets/logos/ionic.png"
            alt="Ionic"
            title="Ionic"
          />
          <img
            loading="lazy"
            src="./assets/logos/react.svg"
            alt="React"
            title="React"
          />
        </div>
      </div>
    </div>

    <div class="glass-card mt-6 skill-category dev-bg" tabindex="0">
      <div class="subtitle card-title color-backend">Backend</div>
      <!-- <img class="skill-level-icon" src="./assets/icons/skill-levels/backend-skill.svg" /> -->
      <div id="be-offer-1" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-backend col-lg-6 d-flex flex-column justify-content-center align-items-center"
        >
          <p>Backend</p>
          <p>Database design</p>
        </div>
        <div
          class="mb-5 col-lg-6 d-flex justify-content-center align-items-center flex-wrap gap-3"
        >
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/nodejs-white.png"
            height="80px"
            alt="NodeJs"
            title="NodeJs"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/nodejs.png"
            height="80px"
            alt="NodeJs"
            title="NodeJs"
          />
          <img
            class="d-none-light"
            loading="lazy"
            src="./assets/logos/mongodb-white.png"
            alt="mongoDB"
            title="mongoDB"
          />
          <img
            class="d-none-dark"
            loading="lazy"
            src="./assets/logos/mongodb.png"
            alt="mongoDB"
            title="mongoDB"
          />
          <img
            loading="lazy"
            src="./assets/logos/firebase.png"
            alt="Firebase"
            title="Firebase"
          />
        </div>
      </div>
      <div id="be-offer-2" class="p-2 p-md-3 p-lg-4 row marker-outline">
        <div
          class="offer-text color-backend col-lg-6 d-flex flex-column align-items-center"
        >
          <div>
            <img
              class="d-none-light"
              loading="lazy"
              src="./assets/logos/git-white.png"
              alt="Git"
              title="Git"
            />
            <img
              class="d-none-dark"
              loading="lazy"
              src="./assets/logos/git.png"
              alt="Git"
              title="Git"
            />
          </div>
          <p>Infrastructure</p>
        </div>
        <div
          class="offer-text color-backend col-lg-6 d-flex flex-column align-items-center"
        >
          <div>
            <img
              class="d-none-light"
              loading="lazy"
              src="./assets/logos/jira-white.png"
              alt="Jira"
              title="Jira"
            />iframeParameters
            <img
              class="d-none-dark"
              loading="lazy"
              src="./assets/logos/jira.png"
              alt="Jira"
              title="Jira"
            />
          </div>
          <p>Project architecture & management</p>
        </div>
      </div>
    </div>

    <app-action-card></app-action-card>
  </div>

  <div id="about" class="about mt-6 pl-2 pl-sm-0 pr-2 pr-sm-0">
    <div class="title sticky">About</div>

    <!-- <div class="glass-card mt-6 p-0 overflow-hidden" tabindex=0>
      <div class="row">
        <div
          class="col-xl-6 article-text d-flex flex-column justify-content-around align-items-center text-center pt-5 pb-0 pb-xl-5 pl-5 pr-5">
          <div>
            <p class="article-text">
              I'm a web and mobile design and developer with 7+ years of professional experience and a passion for creating
              digital experiences with no compromise on quality.
            </p>
            <br>
          </div>
          <div class="w-100 text-center mb-4">My passions</div>
          <div class="d-flex flex-column flex-nowrap flex-md-row flex-md-wrap justify-content-between mb-4">
            <div class="passion-item m-3">
              <div class="p-3 m-3">
                Photography
              </div>
            </div>
            <div class="passion-item m-3">
              <div class="p-3 m-3">
                Discovering people
              </div>
            </div>
            <div class="passion-item m-3">
              <div class="p-3 m-3">
                Traveling
              </div>
            </div>
            <div class="passion-item m-3">
              <div class="p-3 m-3">
                Biking
              </div>
            </div>
            <div class="passion-item m-3">
              <div class="p-3 m-3">
                Listening to music
              </div>
            </div>
            <div class="passion-item m-3">
              <div class="p-3 m-3">
                Laughing with friends
              </div>
            </div>
          </div>
        </div>
        <div class="align-items-center col-xl-6 d-flex justify-content-center">
          <img class="w-100 h-100" style="object-fit: cover" loading="lazy" src="/assets/images/interests/photo.png" />
        </div>
      </div>
    </div> -->

    <project-card
      [projectTitle]="interests.title"
      [projectIndex]="-1"
      [projectImages]="interests.pictures"
      contentWidth="8"
    >
      <div
        mobileContent
        class="col-xl-6 article-text d-flex flex-column justify-content-around align-items-center text-center"
      >
        <div>
          <p class="article-text">
            I'm a web and mobile design and developer with 7+ years of
            professional experience and a passion for creating digital
            experiences with no compromise on quality.
          </p>
          <br />
        </div>
        <div class="w-100 text-center mb-4">Things I also enjoy</div>
        <div
          class="d-flex flex-column flex-nowrap flex-md-row flex-md-wrap justify-content-between mb-4"
        >
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Photography</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">
              Discovering cultures
            </div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Traveling</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Biking</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Music</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Snowboarding</div>
          </div>
        </div>
      </div>

      <div
        desktopContent
        class="article-text d-flex flex-column justify-content-around align-items-center text-center h-100"
      >
        <div>
          <p class="article-text">
            I'm a web and mobile design and developer with 7+ years of
            professional experience and a passion for creating digital
            experiences with no compromise on quality.
          </p>
          <br />
        </div>
        <div class="w-100 text-center mb-4">Things I also enjoy</div>
        <div
          class="d-flex flex-column flex-nowrap flex-md-row flex-md-wrap justify-content-between"
        >
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Photography</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">
              Discovering cultures
            </div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Traveling</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Biking</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Music</div>
          </div>
          <div class="passion-item m-1 m-sm-2 m-lg-3">
            <div class="p-1 p-sm-2 p-lg-3 m-1 m-sm-2 m-lg-3">Snowboarding</div>
          </div>
        </div>
      </div>
    </project-card>
  </div>
</div>

<app-footer id="contact"></app-footer>

<a href="#contact" (click)="closeMobileMenu()">
  <div
    id="contact-bubble"
    class="contact-bubble show-in-menu d-flex align-items-center justify-content-center animate-on-hover"
  >
    <icon-write-envelope></icon-write-envelope>
  </div>
</a>

<!-- <div (click)="goToProjects()">
  <div id="contact-bubble" class="contact-bubble d-flex align-items-center justify-content-center">
    <img src="./assets/icons/message-icon.svg" />
  </div>
</div> -->
