import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-theme-toggler',
  templateUrl: './theme-toggler.component.html',
  styleUrls: ['./theme-toggler.component.scss']
})
export class ThemeTogglerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
