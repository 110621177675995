<div [id]="'general-backdrop-' + projectIndex" class="general-backdrop">
  <img
    src="./../../../assets/icons/close-icon-thin.svg"
    alt="Close button"
    title="close button"
    class="close-button"
  />
  <div class="carousel-control-wrapper">
    <div
      [id]="'carouselControl-prev-' + projectIndex"
      class="carousel-control carousel-control-left"
    >
      <icon-indicator-left></icon-indicator-left>
    </div>
  </div>

  <div class="carousel-control-wrapper">
    <div
      [id]="'carouselControl-next-' + projectIndex"
      class="carousel-control carousel-control-right"
    >
      <icon-indicator-right></icon-indicator-right>
    </div>
  </div>
</div>

<div
  class="glass-card mt-6 pb-0 cursor-pointer"
  [id]="'project-card-container-' + projectIndex"
  (click)="clickOnCard()"
  tabindex="0"
>
  <div *ngIf="projectTitle" class="subtitle card-title color-primary">
    {{ projectTitle }}
  </div>
  <div class="row w-100 mr-0 ml-0">
    <div class="col-lg-6 card-content mb-4 mb-lg-0 d-lg-none cursor-pointer">
      <div
        [ngStyle]="{ 'padding-bottom': projectIndex !== -1 ? '52px' : '0px' }"
      >
        <ng-content select="[mobileContent]"></ng-content>
        <div *ngIf="projectIndex >= 0">
          <div
            (click)="clickOnCard()"
            class="primary-gradient-button action-button"
            tabindex="0"
          >
            View project
          </div>
        </div>
        <div *ngIf="projectIndex == -2">
          <div
            (click)="
              openPrototype(
                $event,
                nftPlatformContent.iframeURL,
                nftPlatformContent.iframeParameters
              )
            "
            class="primary-gradient-button action-button"
            tabindex="0"
          >
            Open prototype
          </div>
        </div>
      </div>
    </div>
    <div
      [id]="'animated-horizontal-scroll-' + projectIndex"
      class="pb-3 pb-md-5 pictures-container scrollable"
      (click)="stopPropagationOnScroll($event)"
    >
      <div
        [id]="'project-card-content-' + projectIndex"
        [class]="'col-' + contentWidth"
        class="sticky card-content pr-0 d-none d-lg-flex"
      >
        <div
          [ngStyle]="{ 'padding-bottom': projectIndex !== -1 ? '52px' : '0px' }"
        >
          <ng-content select="[desktopContent]"></ng-content>
          <div *ngIf="projectIndex >= 0">
            <div
              (click)="clickOnCard()"
              class="primary-gradient-button action-button"
              tabindex="0"
            >
              View project
            </div>
          </div>
          <div *ngIf="projectIndex == -2">
            <div
              (click)="
                openPrototype(
                  $event,
                  nftPlatformContent.iframeURL,
                  nftPlatformContent.iframeParameters
                )
              "
              class="primary-gradient-button action-button"
              tabindex="0"
            >
              Open prototype
            </div>
          </div>
        </div>
      </div>
      <div
        *ngFor="let picture of projectImages; index as i"
        class="project-image-container"
        (click)="clickOnPicture($event, i)"
      >
        <!-- <img [alt]="projectTitle" [title]="projectTitle" class="project-image" [srcset]="picture.replace('/thumbnails', '/full-res') + ' 2x ' + picture.replace('/thumbnails', '/full-res') + ' 3x '" [src]="picture" loading="lazy" draggable="false"> -->
        <picture>
          <!-- Bring the full resolution image for the mobile because it has more pixel density -->
          <source
            media="(max-width: 799px)"
            [srcset]="picture.replace('/thumbnails', '/full-res')"
          />
          <source media="(min-width: 800px)" [srcset]="picture" />
          <img
            [alt]="projectTitle"
            [title]="projectTitle"
            class="project-image"
            [src]="picture"
            loading="lazy"
            draggable="false"
          />
        </picture>
      </div>
      <div class="project-card-indicators">
        <div
          *ngIf="showLeftScrollControl"
          (mousedown)="startScroll($event, -1)"
          (mouseup)="stopScroll()"
          (mouseleave)="stopScroll()"
          class="scroll-control scroll-control-left d-none d-lg-block"
        >
          <div class="scroll-control-visual">
            <icon-indicator-left></icon-indicator-left>
          </div>
        </div>
        <div
          [class]="showRightScrollControl ? 'visible' : 'hidden'"
          (mousedown)="startScroll($event, 1)"
          (mouseup)="stopScroll()"
          (mouseleave)="stopScroll()"
          class="scroll-control scroll-control-right d-none d-lg-flex"
        >
          <div class="scroll-control-visual">
            <icon-indicator-right></icon-indicator-right>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
