<div class="icon-container">
  <svg width="24" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path id="standing-frame"
        d="M4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7C5.34315 4 4 5.34315 4 7Z"
        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path id="closing-frame" d="M11 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H11V3Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
