import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mail-signature',
  templateUrl: './mail-signature.component.html',
  styleUrls: ['./mail-signature.component.scss'],
})
export class MailSignatureComponent implements OnInit {
  constructor(private router: Router) {}

  goHome() {
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'auto';

    this.router.navigateByUrl('');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth';
  }

  ngOnInit(): void {}
}
