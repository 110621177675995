<div class="icon-container">
  <svg width="24" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path id="standing-frame"
        d="M1 7V17C1 18.6569 2.34315 20 4 20H14C15.6569 20 17 18.6569 17 17V7C17 5.34315 15.6569 4 14 4H4C2.34315 4 1 5.34315 1 7Z"
        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path id="opening-frame"
        d="M12 9C12 7.34315 13.3431 6 15 6H19C20.6569 6 22 7.34315 22 9V15C22 16.6569 20.6569 18 19 18H15C13.3431 18 12 16.6569 12 15V9Z"
        fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
