import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  AngularFirestore
} from '@angular/fire/firestore';
const confetti = require('canvas-confetti');

@Component({
  selector: 'rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit {
  constructor(private afs: AngularFirestore) {}

  @Input() project: string;

  rating = 0;
  allRatings = {};
  ratingLabel = "How would you rate this?";
  canRate = true;

  ngOnInit() {
    this.allRatings = this.getLocalRatings();
    if (this.allRatings[this.project] && (this.allRatings[this.project].rating > 0)) {
      this.rating = this.allRatings[this.project].rating;
    }
  }

  commitRating(rating) {
    let ratingObject = {
      timestamp: new Date(),
      rating: rating
    }
    this.allRatings[this.project] = ratingObject;
    this.canRate = false;

    this.afs.collection("projects").doc(this.project).collection("ratings").add(ratingObject)
      .then((res) => {
        // console.log(res);
        // console.log("Thanks 😊");
        // console.log(this.allRatings);

        localStorage.setItem("ratings", JSON.stringify(this.allRatings));
        this.rating = rating;
        this.canRate = true;
      })
      .catch((err) => {
        // console.log(err);
        this.ratingLabel = "Sorry, there was an error 😢";
        this.rating = 0;
        this.canRate = true;
        setTimeout(function () {
          this.ratingLabel = "Let's try again 😎";
        }.bind(this), 3000);
      });

  }

  getLocalRatings() {
    let allRatings = JSON.parse(localStorage.getItem("ratings"));
    // console.log(allRatings);
    if (allRatings) {
      if (allRatings[this.project] && allRatings[this.project].timestamp) {
        allRatings[this.project].timestamp = new Date(Date.parse(allRatings[this.project].timestamp));
        this.ratingLabel = "Thanks for your feedback 😊";
      }
    } else {
      allRatings = {};
      allRatings[this.project] = {}
    };

    return allRatings;
  }

  rate(star) {
    // I want a user to rate maximum 1 per hour
    let cooldownSeconds = 30;
    if (!this.canRate) return;
    if (this.allRatings && this.allRatings[this.project] && this.allRatings[this.project].timestamp) {
      let lastRatingAt = this.allRatings[this.project].timestamp;

      // console.log(lastRatingAt, lastRatingAt.getTime());
      var diff = this.diffHours(lastRatingAt);
      // console.log(new Date(), new Date().getTime());
      // console.log(diff);

      if (diff < cooldownSeconds / 60 / 60) {
        this.ratingLabel = "Wait a bit, quickfinger 😋";
        setTimeout(() => {
          this.ratingLabel = "How would you rate this?";
        }, cooldownSeconds * 1000);
        return;
      }
    }
    this.ratingLabel = "Thanks for your feedback 😊"
    if (window.innerWidth < 1310) {
      // On mobile phones, make the confetti appear from body
      this.blowConfetti(document.body, "fixed");
    } else {
      this.blowConfetti(document.getElementById("rating-stars-container"), "absolute");
    }
    // this.blowConfetti(document.body, "fixed");
    this.commitRating(star);
  }

  diffHours(date: Date): number {
    let now = new Date();
    let utcThis = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    let utcOther = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

    return (utcThis - utcOther) / 3600000;
  };

  blowConfetti(element, position) {
    // console.log(element);
    let myCanvas = document.createElement('canvas');
    myCanvas.setAttribute("id", "confetti-canvas");
    myCanvas.style.position = position;
    myCanvas.style.width = "500px";
    myCanvas.style.height = "800px";
    myCanvas.style.top = "50%";
    myCanvas.style.left = "50%";
    myCanvas.style.transform = "translate(-50%, -50%)";
    myCanvas.style.pointerEvents = "none";
    myCanvas.style.zIndex = "9999";

    element.appendChild(myCanvas);

    let myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });

    myConfetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: 0.6
      }
    }).then(() => {
      // console.log("no more confetti");
      let confettiCanvas = document.getElementById("confetti-canvas");
      if (confettiCanvas) {
        confettiCanvas.remove();
      }
    });

    // setTimeout(() => {
    //   myConfetti.reset();
    // }, 2000);    
  }
}
