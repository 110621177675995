<div class="example-container">
    <h2>Clienti</h2>
  
    <div
      id="customers"
      cdkDropList
      [cdkDropListData]="customers"
      [cdkDropListConnectedTo]="['customers', 'table1', 'table2', 'VIPtables']"
      class="example-list"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListEnterPredicate]="alwaysTruePredicat">
      <div
        class="example-box"
        *ngFor="let number of customers"
        [cdkDragData]="number"
        cdkDrag>{{number}}</div>
    </div>
  </div>
  
  <div class="example-container">
    <h2>Masa 1</h2>
  
        <div
        id="table1"
        cdkDropList
        [cdkDropListData]="table1"
        [cdkDropListConnectedTo]="['customers', 'table1', 'table2', 'VIPtables']"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="normalTablePredicat">
        <div
            class="example-box"
            *ngFor="let number of table1"
            cdkDrag
            [cdkDragData]="number">{{number}}</div>
        </div>    
    </div>

<div class="example-container">
    <h2>Masa 2</h2>
      
        <div
            id="table2"
            cdkDropList
            [cdkDropListData]="table2"
            [cdkDropListConnectedTo]="['customers', 'table1', 'table2', 'VIPtables']"
            class="example-list"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="normalTablePredicat">
            <div
            class="example-box"
            *ngFor="let number of table2"
            cdkDrag
            [cdkDragData]="number">{{number}}</div>
        </div>
</div>

<div class="example-container">
    <h2>Masa VIP</h2>
      
    <div
    id="VIPtables"
    cdkDropList
    [cdkDropListData]="VIPtables"
    [cdkDropListConnectedTo]="['customers', 'table1', 'table2', 'VIPtables']"
    class="example-list"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListEnterPredicate]="VIPTablePredicat">
    <div
      class="example-box"
      *ngFor="let number of VIPtables"
      cdkDrag
      [cdkDragData]="number">{{number}}</div>
</div>
