<div
  class="glass-card mt-6 skill-category consultancy-bg text-center p-3 p-md-4 p-lg-5"
  tabindex="0"
>
  <div class="subtitle text-primary mt-4 mt-md-0">Wanna work with me?</div>
  <div class="w-100 pt-0 pt-lg-4">
    <div class="article-text">
      <p class="">Do you want to build a project?</p>
      <p class="">Or you have issues in an area where I have experience?</p>
      <p class="mt-5">Let’s discuss and see how can I help you</p>
    </div>
    <div
      class="d-flex justify-content-center justify-content-lg-end mt-5 mb-4 mb-lg-0"
    >
      <div class="mr-lg-20" tabindex="-1">
        <button
          mat-button
          class="mr-lg-20 action-button consultancy-button"
          tabindex="0"
          (click)="openContactForm()"
        >
          Contact me
        </button>
      </div>
    </div>
  </div>
</div>
