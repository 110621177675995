<!-- <iframe style="height: 100vh; width: 100vw; border: none; margin-bottom: -5px;" width="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmNAtBkfIMTn5J60u3QbJL9%2Ftires%3Fnode-id=660%3A823&scaling%3Dscale-down-width%26page-id%3D145%253A331%26starting-point-node-id=660%3A823%26hide-ui%3D1" allowfullscreen></iframe> -->

<!-- <iframe style="height: 100vh; width: 100vw; border: none; margin-bottom: -5px;" width="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmNAtBkfIMTn5J60u3QbJL9%2Ftires%3Fnode-id%3D145%253A334%26scaling%3Dscale-down-width%26page-id%3D145%253A331%26starting-point-node-id%3D523%253A512%26hide-ui%3D1" allowfullscreen></iframe> -->

<div class="dark-blue-theme">
  <div>
    <bg-shapes></bg-shapes>
    <custom-cursor id="cursor-container"></custom-cursor>

    <div class="container sticky" style="top: 20px; z-index: 2;">
      <div class="d-block" style="position: relative ">
        <div class="nav-bar d-flex justify-content-end align-items-center sticky">
          <div>

            <div>
              <div class="back-button animate-on-hover d-flex justify-content-between align-items-center float-right"
                (click)="goHome()">
                <icon-long-arrow-back></icon-long-arrow-back>
                <span class="ml-3">Home</span>
              </div>
            </div>

            <div class="float-right">
              <div id="show-prototype-button"
                class="d-flex d-xl-none back-button slide-icon animate-on-hover mt-3 justify-content-between align-items-center float-right"
                (click)="showPrototype()" (mouseover)="preparePrototypeTransition()">
                <span>Show</span>
                <icon-slide-open class="ml-2"></icon-slide-open>
              </div>

              <div id="hide-prototype-button"
                class="d-none d-xl-flex back-button slide-icon animate-on-hover mt-3 justify-content-between align-items-center float-right"
                (click)="hidePrototype()">
                <span>Hide&nbsp;</span>
                <icon-slide-close class="ml-2"></icon-slide-close>
              </div>
              <div
                class="d-none d-xl-flex back-button fullscreen-icon animate-on-hover mt-3 mr-2 justify-content-between align-items-center float-right"
                (click)="openPrototype()" (mouseover)="preparePrototypeTransition()">
                <icon-internal-fullscreen></icon-internal-fullscreen>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="container mb-6 d-flex">
      <div class="title">Școala de business</div>
    </div>

    <div class="container d-flex flex-wrap" style="z-index: 1;">
      <div tabindex=-1 (click)="openPrototype()" (mouseover)="preparePrototypeTransition()" class="mt-4 mr-4">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Figma prototype
          <icon-internal-fullscreen class="ml-2"></icon-internal-fullscreen>
        </button>
      </div>
      <a tabindex=-1 class="mt-4 mr-4" href="https://www.bcrscoaladebusiness.ro/" target="_blank" rel="noopener"
        aria-label="iziBAC website">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Website
          <icon-external-link class="ml-2"></icon-external-link></button>
      </a>
    </div>


    <div class="container d-block d-xl-flex">
      <div class="">
        <div id="projects" class="projects">
        </div>

        <div class="glass-card mt-5" tabindex=0>
          <div class="subtitle card-title color-primary">
            About project
          </div>
          <div class="card-content">
            <div class="d-flex flex-column h-100 justify-content-between">
              <p class="article-text">
                Școala de Business is an online, gamified, learning web platform which contains practical business
                advice for novice and experienced entrepreneurs.
              </p>
              <br>
              <p class="article-text">
                It’s a place where people have access to video lessons, articles and quizzes that are essential to the
                business world. The more you learn, the more points and achievements you get.
              </p>
              <br>
              <div class="article-text">
                <b>Some skills I learned:</b>
                <li>
                  Product design
                </li>
                <li>
                  Product development
                </li>
                <li>
                  Business partnerships
                </li>
              </div>
              <br>
            </div>
          </div>
        </div>
        <div class="glass-card mt-5" tabindex=0>
          <div class="subtitle card-title color-primary">
            Context
          </div>
          <div class="card-content">
            <div class="d-flex flex-column h-100 justify-content-between">
              <p class="article-text">
                Following up iziBAC’s success, BCR approached us (iziBAC team) in order to help them build this online
                platform for entrepreneurs.
              </p>
              <br>
              <p class="article-text">
                BCR is a subsidiary of Erste Group Bank, one of the largest financial services providers in Central
                and Eastern Europe.
              </p>
              <br>
              <div class="article-text">
                <b>Our team:</b>
                <li>
                  Me - UX, UI, Product design, Frontend dev
                </li>
                <li>
                  Adrian - Content & Copywriting
                </li>
                <li>
                  Miu - Gamification expert
                </li>
              </div>
              <br>
              <div class="article-text">
                <b>Period:</b>
                <li>
                  2019 - 2020
                </li>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xl-6 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Scoala de business learning area 1" title="Scoala de business learning area 1"
                class="w-100 border-radius-20" src="./../../../assets/images/bcr/full-res/learning-1.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-xl-6 mt-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Scoala de business learning area 2" title="Scoala de business learning area 2"
                class="w-100 border-radius-20" src="./../../../assets/images/bcr/full-res/learning-2.jpg" tabindex=0 />
            </div>
          </div>
        </div>

        <div class="">
          <div class="glass-card mt-0" tabindex=0>
            <div class="subtitle card-title color-primary">
              Process: UX
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <p class="article-text">
                  The project had a very tight deadline and there was a huge pressure on getting it done on time.
                  <!-- It was pretty chaotic. Everything was on the rush, we didn't have a clear idea of what we needed. -->
                </p>
                <p class="article-text">
                  The whole thing took place during a very busy period of the year and we had 2 months to design,
                  develop, test and release. The development phase was made in collaboration with another partner.
                </p>
                <p class="article-text">
                  Our role was to get it done no matter what because the platform was already promised to key
                  stakeholders and to the market. Also, this platform would have been a major sponsor behind a new
                  “Dragon’s Den” type of show on one of Romania’s biggest TV channels - PRO TV. So it had huge exposure.
                </p>
                <p class="article-text">
                  There was no time for full, in-depth research but fortunately the persona for the platform was
                  basically us: young entrepreneurs with small or medium businesses, tech oriented, eager to learn
                  and to have everything online and gamified. So we were the perfect test subjects.
                  I started with a wireframe and validated it with BCR and some other potential users.
                </p>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <div class="mt-5">
                <div class="col-12 p-0">
                  <img loading="lazy" alt="Scoala de business article" title="Scoala de business article"
                    class="w-100 border-radius-20" src="./../../../assets/images/bcr/full-res/article-1.jpg"
                    tabindex=0 />
                </div>
              </div>
            </div>
          </div>

          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              Process: UI + DEV
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <p class="article-text">
                  I was working continuously, building parts of the UI in Figma, validating it, coding the interface
                  (no framework) and managing the way it was implemented by the other development company on their
                  boilerplate/framework/platform.
                </p>
                <p class="article-text">
                  After making sure we had something functional, I then continued with the design for other
                  functionalities and components and repeated the process.
                </p>
                <p class="article-text">
                  We were designing and building the platform in short, intense increments - a more compact, high
                  frequency version of Agile Methodology.
                </p>
                <p class="article-text">
                  We successfully released the platform right before the first episode of the TV show was on air on
                  national television. Despite everything, the project was finished and generated great success. </p>
              </div>
            </div>
          </div>

        </div>

        <div class="p-0">
          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              My responsibilities
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <div class="row">
                  <div class="article-text col-12">
                    <b>UX, UI, Product design</b>
                    <p class="article-text">
                      Part of the team that designed the whole concept from the idea stage. Responsible for putting
                      ideas into a dynamic wireframe, validate it, and create the graphic interface.
                    </p>
                  </div>
                  <br>
                  <div class="article-text col-12">
                    <b>Frontend development & management</b>
                    <p class="article-text">
                      Making sure the development is happening and the priorities are aligned with the deadline. After I
                      implemented the frontend part I was had to make sure the company we worked with was integrating
                      the
                      frontend into their already build backbone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xl-6 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Scoala de business dashboard" title="Scoala de business dashboard"
                class="w-100 border-radius-20" src="./../../../assets/images/bcr/full-res/dashboard-1.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-xl-6 mt-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Scoala de business profile" title="Scoala de business profile"
                class="w-100 border-radius-20" src="./../../../assets/images/bcr/full-res/profile-1.jpg" tabindex=0 />
            </div>
          </div>
        </div>


        <div class="p-0">
          <div class="glass-card" tabindex=0>
            <div class="subtitle card-title color-primary">
              Technologies
            </div>
            <div class="card-content article-text">
              <div class="d-flex h-100 justify-content-around flex-wrap">
                <div class="tech-item">
                  <img loading="lazy" alt="HTML" title="HTML" src="./../../../assets/logos/html.png" />
                  <div class="text-center w-100 mt-4">
                    <b>HTML</b>
                  </div>
                </div>
                <div class="tech-item pl-5 pr-5">
                  <img loading="lazy" alt="Figma" title="Figma" src="./../../../assets/logos/figma-simple.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Figma</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="CSS" title="CSS" src="./../../../assets/logos/css.png" />
                  <div class="text-center w-100 mt-4">
                    <b>CSS</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="Pure javascript" title="Pure javascript"
                    src="./../../../assets/logos/js.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Vanilla JS</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="Bootstrap" title="Bootstrap" src="./../../../assets/logos/bootstrap.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Bootstrap</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="prototype-backdrop" (click)="closePrototype()">
      </div>
      <div id="prototype-container" class="prototype-container d-xl-block">
        <div class="desktop-frame ml-5 mt-5">
          <iframe id="prototype-iframe" style="height: 100%; width: 100%; border: none; margin-bottom: -5px;"
            width="100%" title="Figma prototype"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F9ovotSNs3JsxnoTWwwJ8M0%2F%255BBCR%255D-Scoala-de-business%3Fnode-id%3D1095%253A7276%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D1095%253A7276%26hide-ui%3D1"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <rating-stars [project]="'scoala-de-business'"></rating-stars>

  <projects-navigator [previousProject]="'peteco'" [nextProject]="'izibac'">
  </projects-navigator>

  <a href="scoala-de-business#contact">
    <div id="contact-bubble"
      class="contact-bubble visible d-flex align-items-center justify-content-center animate-on-hover">
      <icon-write-envelope></icon-write-envelope>
    </div>
  </a>

  <app-footer id="contact"></app-footer>
</div>
