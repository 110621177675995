<!-- <custom-cursor id="cursor-container"></custom-cursor> -->

<div class="container sticky" style="top: 20px; z-index: 2">
  <div class="d-block" style="position: relative">
    <div class="nav-bar d-flex justify-content-end align-items-center sticky">
      <div>
        <div>
          <div
            class="back-button animate-on-hover d-flex justify-content-between align-items-center float-right"
            (click)="goHome()"
          >
            <icon-long-arrow-back></icon-long-arrow-back>
            <span class="ml-3">Home</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pt-5 position-relative">
  <iframe
    class="iframe-container"
    src="https://eduard-form.web.app/"
    width="100%"
    height="100%"
  ></iframe>
</div>
