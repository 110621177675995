import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RoutingStateService } from '../services/routing-state/routing-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private routingState: RoutingStateService
  ) {
    // console.log(router);
  }

  projects = {
    izibac: {
      title: 'iziBAC',
      pictures: [
        './assets/images/iziBAC/thumbnails/dashboard-day.jpg',
        './assets/images/iziBAC/thumbnails/extracurricula-day.jpg',
        './assets/images/iziBAC/thumbnails/subject-day.jpg',
        './assets/images/iziBAC/thumbnails/audio-lesson-1.jpg',
        './assets/images/iziBAC/thumbnails/video-lesson-1.jpg',
        './assets/images/iziBAC/thumbnails/main-game-purple.jpg',
        './assets/images/iziBAC/thumbnails/main-game-boosters-purple.jpg',
        './assets/images/iziBAC/thumbnails/end-game-purple.jpg',
        './assets/images/iziBAC/thumbnails/main-game-green.jpg',
        './assets/images/iziBAC/thumbnails/main-game-boosters-green.jpg',
        './assets/images/iziBAC/thumbnails/end-game-green.jpg',
        './assets/images/iziBAC/thumbnails/daily-rewards.jpg',
        './assets/images/iziBAC/thumbnails/leaderboard-day.jpg',
        './assets/images/iziBAC/thumbnails/market-1-day.jpg',
        './assets/images/iziBAC/thumbnails/market-2-night.jpg',
        './assets/images/iziBAC/thumbnails/dashboard-night.jpg',
        './assets/images/iziBAC/thumbnails/leaderboard-night.jpg',
      ],
    },
    bcr: {
      title: 'BCR Școala de business',
      pictures: [
        './assets/images/bcr/thumbnails/landing-page-1.jpg',
        './assets/images/bcr/thumbnails/landing-page-2.jpg',
        './assets/images/bcr/thumbnails/landing-page-3.jpg',
        './assets/images/bcr/thumbnails/dashboard-1.jpg',
        './assets/images/bcr/thumbnails/learning-1.jpg',
        './assets/images/bcr/thumbnails/learning-2.jpg',
        './assets/images/bcr/thumbnails/article-1.jpg',
        './assets/images/bcr/thumbnails/profile-1.jpg',
      ],
    },
    peteco: {
      title: 'Peteco mobile app',
      pictures: [
        './assets/images/peteco/thumbnails/login.jpg',
        './assets/images/peteco/thumbnails/index.jpg',
        './assets/images/peteco/thumbnails/search.jpg',
        './assets/images/peteco/thumbnails/search-2.jpg',
        './assets/images/peteco/thumbnails/product-page-1.jpg',
        './assets/images/peteco/thumbnails/product-page-2.jpg',
        './assets/images/peteco/thumbnails/product-page-3.jpg',
        './assets/images/peteco/thumbnails/cart.jpg',
        './assets/images/peteco/thumbnails/order.jpg',
        './assets/images/peteco/thumbnails/reviews.jpg',
        './assets/images/peteco/thumbnails/add-review.jpg',
        './assets/images/peteco/thumbnails/menu.jpg',
        './assets/images/peteco/thumbnails/recover-password.jpg',
        './assets/images/peteco/thumbnails/profile.jpg',
      ],
    },
    nftPlatform: {
      title: 'NFT Platform',
      pictures: [
        './assets/images/nftPlatform/thumbnails/1.webp',
        './assets/images/nftPlatform/thumbnails/2.webp',
        './assets/images/nftPlatform/thumbnails/3.webp',
        './assets/images/nftPlatform/thumbnails/4.webp',
        './assets/images/nftPlatform/thumbnails/5.webp',
      ],
    },
    findYourWhy: {
      title: 'Find Your Why',
      pictures: [
        './assets/images/findYourWhy/thumbnails/1.webp',
        './assets/images/findYourWhy/thumbnails/2.webp',
        './assets/images/findYourWhy/thumbnails/3.webp',
        './assets/images/findYourWhy/thumbnails/4.webp',
        './assets/images/findYourWhy/thumbnails/5.webp',
        './assets/images/findYourWhy/thumbnails/6.webp',
        './assets/images/findYourWhy/thumbnails/7.webp',
        './assets/images/findYourWhy/thumbnails/8.webp',
      ],
    },
  };

  interests = {
    title: '',
    pictures: [
      './assets/images/interests/vienna.jpg',
      './assets/images/interests/vienna-2.jpg',
      './assets/images/interests/vienna-4.jpg',
      './assets/images/interests/berlin.jpg',
      './assets/images/interests/berlin-3.jpg',
      './assets/images/interests/berlin-bike.jpg',
      './assets/images/interests/bucharest.jpg',
      './assets/images/interests/romania-2.jpg',
      './assets/images/interests/serbia.jpg',
      // "./assets/images/interests/vienna-5.jpg",
    ],
  };

  years = [];
  timelineProjects = [
    {
      startDate: '2013',
      endDate: '2017',
      displayStartDate: '2013',
      displayEndDate: '2017',
      svgPath: './assets/timeline/upb.svg',
      isInChart: true,
      isInList: true,
      name: 'upb',
      displayName: 'UPB',
      logoPath: './assets/logos/upb.png',
      website: 'https://upb.ro/en/',
      description:
        'My formal education. University Politechnica of Bucharest. Faculty of Automatic control and computer engineering.',
      overallRole: 'Student',
      subprojectsLabel: 'Relevant projects:',
      subprojects: [
        {
          title: "Bachelor's project",
          description:
            'A wearable electronic device that analyses your posture in you chair and notifies trough a mobile app when you sit too much in a bad posture',
          role: 'Student',
          technologies: 'Angular 2+, NodeJS, Firebase',
        },
      ],
    },
    {
      startDate: '2014',
      endDate: '2017',
      displayStartDate: '2014',
      displayEndDate: '2017',
      svgPath: './assets/timeline/best.svg',
      isInChart: true,
      isInList: true,
      name: 'best',
      displayName: 'BEST Bucharest',
      logoPath: './assets/logos/best.png',
      website: 'https://best.eu.org/',
      description:
        'International non-profit and non-political student organization with 92 Local BEST Groups (LBG) in 33 countries.',
      overallRole: 'Member',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'BESTEM Hackathon',
          description: '',
          role: 'Fundraising coordinator',
          technologies: 'People management, sales, events planning',
        },
        {
          title: 'LBG Bucharest Board XXII',
          description: '',
          role: 'Vice president for Corporate Relations',
          technologies: 'People management, sales',
        },
      ],
    },
    {
      startDate: '2016',
      endDate: '2018',
      displayStartDate: '2016',
      displayEndDate: '2018',
      svgPath: './assets/timeline/bearingpoint.svg',
      isInChart: true,
      isInList: true,
      name: 'bearingpoint',
      displayName: 'BearingPoint',
      logoPath: './assets/logos/bearingpoint.png',
      website: 'https://www.bearingpoint.com/en/',
      description:
        'BearingPoint delivers management and technology consulting. I was part of a new technology department.',
      overallRole: 'Technology Analyst/Consultant',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'ChangeToGo',
          description: '',
          role: 'Project Manager',
          technologies: 'Ionic 3',
        },
        {
          title: 'Active Manager',
          description: '',
          role: 'UX & Frontend developer',
          technologies: 'Angular 2',
        },
      ],
    },
    {
      startDate: '2017',
      endDate: '2021',
      displayStartDate: '2017',
      displayEndDate: '2021',
      svgPath: './assets/timeline/izibac.svg',
      isInChart: true,
      isInList: true,
      name: 'izibac',
      displayName: 'iziBAC',
      logoPath: './assets/logos/izibac.png',
      website: 'https://www.izibac.ro',
      description:
        'A mobile app that helps high-schoolers learn for the baccalaureate exam in a gamified way.',
      overallRole: 'Co-founder',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'iziBAC application',
          description: '',
          role: 'Product Design, UI, UX, Backend dev',
          technologies: 'Figma, NodeJS, Firebase',
          iframeURL:
            'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FnlZH6ENcJzH25Uk2dhWyLA%2F%255Bedd%255D-%255BiziBAC%255D-Official-prototype-screens%3Fnode-id%3D3720%253A71431%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D3720%253A71431%26hide-ui%3D1',
          iframeParameters: {
            width: { amount: 340, units: 'px' },
            height: { amount: 700, units: 'px' },
          },
          projectURL: 'https://eduard.digital/izibac',
        },
        {
          title: 'Administration platform',
          description: '',
          role: 'Product Design, Frontend developer',
          technologies: 'Angular 2+',
        },
        {
          title: 'BCR Școala de business',
          description: '',
          role: 'Product, UI, UX, Frontend developer',
          technologies: 'VanillaJS, Bootstrap',
          iframeURL:
            'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F9ovotSNs3JsxnoTWwwJ8M0%2F%255BBCR%255D-Scoala-de-business%3Fnode-id%3D1095%253A7276%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D1095%253A7276%26hide-ui%3D1',
          iframeParameters: {
            width: { amount: 65, units: 'vw' },
            height: { amount: 90, units: 'vh' },
          },
          projectURL: 'https://eduard.digital/scoala-de-business',
        },
      ],
    },
    {
      startDate: '2018',
      endDate: '2019',
      displayStartDate: '2018',
      displayEndDate: '2019',
      svgPath: './assets/timeline/swazm.svg',
      isInChart: true,
      isInList: true,
      name: 'swazm',
      displayName: 'SWAZM',
      logoPath: './assets/logos/swazm.png',
      website: 'https://swazm.com/',
      description:
        'A company that develops blockchain based infrastructure and decentralized storage solution for decentralized apps.',
      overallRole: 'Frontend developer',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'Restart Energy Platform',
          description: '',
          role: 'UX, Frontend developer',
          technologies: 'VueJS, Bootstrap',
        },
      ],
    },
    {
      startDate: '2018',
      endDate: '2021',
      displayStartDate: '2018',
      displayEndDate: '2021',
      svgPath: './assets/timeline/effio.svg',
      isInChart: true,
      isInList: true,
      name: 'effio',
      displayName: 'Effio',
      logoPath: './assets/logos/effio.png',
      website: 'https://effio.ro/',
      description:
        'Founded with two of my ex-colleagues, Effio focuses on developing web & mobile apps from idea to business.',
      overallRole: 'Co-founder',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'Peteco',
          description: '',
          role: 'UX & UI Design',
          technologies: 'Figma, React native',
          iframeURL:
            'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FsJQkqJ4P8PgDmKAJfOAkCi%2FEcommerce%3Fnode-id%3D1125%253A5713%26scaling%3Dscale-down-width%26page-id%3D117%253A0%26starting-point-node-id%3D1125%253A5702%26hide-ui%3D1',
          iframeParameters: {
            width: { amount: 340, units: 'px' },
            height: { amount: 700, units: 'px' },
          },
          projectURL: 'https://eduard.digital/peteco',
        },
        {
          title: 'Framey',
          description: '',
          role: 'Backend, Frontend developer',
          technologies: 'Angular 2+, NodeJS, Firebase',
        },
        {
          title: 'PoliApp',
          description: '',
          role: 'Frontend mobile developer',
          technologies: 'Ionic 3',
        },
      ],
    },
    {
      startDate: '2021',
      endDate: '2022',
      displayStartDate: '2021',
      displayEndDate: '',
      svgPath: '',
      isInChart: true,
      isInList: true,
      name: 'portfolio',
      displayName: 'Eduard Lache',
      // logoPath: './assets/logos/logo-edd-shape-white-sky.svg',
      logoPath: './assets/logos/new-logo-blue-edd.webp',
      website: 'https://eduard.digital',
      description:
        'Freelance projects acquired through eduard.digital or other freelancing platforms.',
      overallRole: 'Design & Developer',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'eduard.digital ',
          description:
            'This very own portfolio which can also be used as a template for your website. Drop a message if interested',
          role: 'UX, UI, Developer',
          technologies: 'Figma, Angular 11, Bootstrap',
        },
        {
          title: 'NFT Marketplace design',
          description: 'A design for an NFT Marketplace Landing page',
          iframeURL: 'https://nft-galaxy-marketplace.web.app/',
          // 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3f0sQhGxrHw147DXR6Rari/Public-%5BART-Collection%5D?type=design&node-id=1-858&t=jcwhQMaNH2sOhtob-0&scaling=scale-down-width&page-id=0%3A1&starting-point-node-id=1%3A858&hide-ui=1',
          iframeParameters: {
            width: { amount: 80, units: 'vw' },
            height: { amount: 90, units: 'vh' },
          },
          role: 'Designer',
          technologies: 'Figma, Spline 3D',
        },
        {
          title: 'Typescript NPM package/sdk for Blockchain',
          description: '',
          role: 'Whole project developer',
          technologies: 'Typescript, WebAssembly, WebWorkers, RPC-WebSockets',
        },
      ],
    },
    {
      startDate: '2021',
      endDate: '2024',
      displayStartDate: '2022',
      displayEndDate: '2023',
      svgPath: './assets/timeline/freelancing.svg',
      isInChart: true,
      isInList: true,
      name: 'freelancing',
      displayName: 'Freelancing',
      // logoPath: './assets/logos/logo-edd-shape-white-sky.svg',
      logoPath: './assets/logos/new-logo-blue-edd.webp',
      website: 'https://eduard.digital',
      description:
        'Freelance projects acquired through eduard.digital or other freelancing platforms',
      overallRole: 'Design & Developer',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'Blockchain browser extension',
          description: 'Similar to Metamask.io',
          role: 'Whole project developer',
          technologies: 'React 17, Webpack 5',
        },
        {
          title: 'E-commerce online store',
          description: '',
          role: 'Designer, Frontend',
          technologies: 'Figma, HTML, CSS, JS, Gomag',
        },
        {
          title: 'Blockchain swap platform',
          description: 'Similar to Uniswap.org',
          role: 'Designer, Frontend',
          technologies: 'Typescript, React 17, Webpack 5',
        },
      ],
    },
    {
      startDate: '',
      endDate: '2024',
      displayStartDate: '2024',
      displayEndDate: '',
      svgPath: './assets/timeline/freelancing.svg',
      isInChart: false,
      isInList: true,
      name: 'freelancing',
      displayName: 'Freelancing',
      // logoPath: './assets/logos/logo-edd-shape-white-sky.svg',
      logoPath: './assets/logos/new-logo-blue-edd.webp',
      website: 'https://eduard.digital',
      description:
        'Freelance projects acquired through eduard.digital or other freelancing platforms',
      overallRole: 'Design & Developer',
      subprojectsLabel: 'Projects',
      subprojects: [
        {
          title: 'Find Your Why',
          description:
            'A website that showcases the first Romanian Business and Motivational Conference.',
          role: 'Designer & Full-stack Developer',
          // iframeURL:
          //   'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%OJj7tSfWFs6EU4spSGVaOa/%5Bed.digital%5D-FindYourWhy---presentation-for-portfolio?type=design&node-id=2-414&t=gOa0LIxpLoPmyrtN-0&scaling=scale-down-width&page-id=2%3A413&starting-point-node-id=2%3A414&hide-ui=1',
          iframeURL: 'https://findyourwhy.ro/',

          iframeParameters: {
            width: { amount: 90, units: 'vw' },
            height: { amount: 90, units: 'vh' },
          },
          technologies: 'React 18, Figma',
        },
      ],
    },
  ];
  reversedTimelineProjects = [];
  intersectionObservers = [];
  defaultIntersectionOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [
      0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
      0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 0,
    ],
  };

  previousScrollY = 0;
  previousScrollRatio = 0;
  removableAnimationHandler;

  ngOnInit(): void {
    // console.log("ngOnInit");
    this.reversedTimelineProjects = this.timelineProjects
      .map((x) => x)
      .reverse();

    this.initTimeline();
    this.loadAnimations();
  }

  ngAfterViewInit(): void {
    setTimeout(
      function () {
        // console.log("ngAfterViewInit home");
        this.initEvolutionChart();
        this.initMenuTracking();
        this.scrollToProperProject();
        this.progressiveLoadImages();
      }.bind(this, document),
      100
    );
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.removableAnimationHandler);
    for (let intrObs of this.intersectionObservers) {
      // console.log(intrObs);
      intrObs.disconnect();
    }
    // ToDo:
    // remove listeners for evolution, menu and timeline
  }

  progressiveLoadImages() {
    let img = document.getElementById('avatar');

    function waitForImageToLoad(imageElement) {
      return new Promise((resolve) => {
        imageElement.onload = resolve;
      });
    }

    waitForImageToLoad(img).then(() => {
      // Image have loaded.
      img['src'] = '/assets/people/me.png';
    });
  }

  loadAnimations(): void {
    let svgModels = [
      'M65.0001 11.5005C71.9888 9.17133 109.499 -12.5 95.8779 61.8198C89.2925 97.7501 46.54 103.178 21.2871 87.7115C-2.57882 73.0945 -2.70256 46.0656 12.2156 24.1741C26.2677 3.55365 42.6897 2.00049 42.6897 2.00049',
      'M66.218 10.9662C104.655 2.0143 109.985 72.4539 57.7806 92.0198C32.4759 101.504 1.99997 94.4558 2 41.5583C2.00004 -20.4742 32.9372 8.20749 32.9372 8.20749',
      'M31.2877 15.7442C58.8269 3.14679 98.6088 -23.0217 95.8654 63.6303C94.5237 106.006 67.6004 90.246 43.9491 94.8741C-10.2144 105.473 -1.07031 37.6765 12.0208 40.8565C18.1347 42.3417 16.9534 54.6325 16.9534 54.6325',
      'M95.8244 32.5162C97.6681 63.1957 84.9718 93.9962 58.8402 93.9963C32.7086 93.9965 1.99996 95.3912 2 43.6622C2.00004 -17 47 6 47 6',
      'M31.2877 15.7744C58.8269 3.14932 98.6088 -23.0767 95.8654 63.7658C94.5237 106.235 67.6004 90.4401 43.9491 95.0783C-10.2144 105.7 -1.07032 20.9463 12.0208 24.1333C18.1347 25.6218 16.9534 37.9396 16.9534 37.9396',
      'M86.7235 1C84.2408 31.2338 117.367 64.3695 71.5929 87.3859C47.0665 99.7185 15.5203 99.0815 4.83956 70.3481C-6.27675 40.4429 16.9626 8.22756 44.7181 9.04592',
      'M84.0351 3.65397C81.6508 30.2277 113.598 70.2602 81.8583 86.8306C57.8187 99.381 16.365 101.75 6.10761 76.4953C-4.56804 50.2104 6.84166 18.6118 25.6508 5.86869C36.1841 -1.26763 55.4839 3.65397 55.4839 3.65397',
    ];

    let svgMapping = [
      {
        elementID: 'ux-offer-1',
        svgModel: svgModels[4],
        strokeColor: '#83f3897c',
      },
      {
        elementID: 'ux-offer-2',
        svgModel: svgModels[1],
        strokeColor: '#83f3897c',
      },
      {
        elementID: 'ui-offer-1',
        svgModel: svgModels[2],
        strokeColor: '#cbafff',
      },
      {
        elementID: 'ui-offer-2',
        svgModel: svgModels[3],
        strokeColor: '#cbafff',
      },
      {
        elementID: 'fe-offer-1',
        svgModel: svgModels[4],
        strokeColor: '#FFD89D',
      },
      {
        elementID: 'fe-offer-2',
        svgModel: svgModels[5],
        strokeColor: '#FFD89D',
      },
      {
        elementID: 'be-offer-1',
        svgModel: svgModels[2],
        strokeColor: '#97ECFF',
      },
      {
        elementID: 'be-offer-2',
        svgModel: svgModels[6],
        strokeColor: '#97ECFF',
      },
    ];

    window.addEventListener(
      'scroll',
      (this.removableAnimationHandler = function () {
        let windowInnerHeight = window.innerHeight;
        let verticalPosition = 0;
        let triggerElement: any = document.getElementById('hero-trigger');
        let avatar = Array.from(
          document.getElementsByClassName(
            'avatar'
          ) as HTMLCollectionOf<HTMLElement>
        );
        let messageBubble = Array.from(
          document.getElementsByClassName(
            'message-bubble'
          ) as HTMLCollectionOf<HTMLElement>
        );

        scrollAnimationsHandler(
          verticalPosition,
          windowInnerHeight,
          triggerElement,
          messageBubble,
          avatar
        );
      }),
      {
        passive: true,
      }
    );

    // //
    // // Add the drawing animation when the scroll is at the right point with the help of IntersectionObserver
    // //
    // for (let svgDrawing of svgMapping) {
    //   let intersectionOptions = {
    //     root: this.defaultIntersectionOptions.root,
    //     rootMargin: this.defaultIntersectionOptions.rootMargin,
    //     threshold: 1.0
    //   }
    //   new IntersectionObserver(this.handleDrawingIntersect.bind("", this.drawAround, svgDrawing), intersectionOptions).observe(document.getElementById(svgDrawing.elementID));
    // }

    function scrollAnimationsHandler(
      verticalPosition,
      windowInnerHeight,
      triggerElement,
      messageBubble,
      avatar
    ) {
      // console.log("windowInnerHeight", windowInnerHeight);

      //
      // Get the scroll positions
      //
      if (pageYOffset)
        //usual
        verticalPosition = pageYOffset;
      else if (document.documentElement.clientHeight)
        //ie
        verticalPosition = document.documentElement.scrollTop;
      else if (document.body)
        //ie quirks
        verticalPosition = document.body.scrollTop;
      else {
        // mobile ?
        verticalPosition = window.scrollY;
      }

      if (verticalPosition < windowInnerHeight) {
        //
        // Do the animations at the top of the page
        //
        triggerElement.style.top = -verticalPosition * 0.5;
        triggerElement.style.transform =
          'rotate(' +
          -verticalPosition / 10 +
          'deg) ' +
          'scale(' +
          (1 + (3 * verticalPosition) / windowInnerHeight) +
          ')';
        triggerElement.style['will-change'] = 'opacity, transform';

        var newOpacity: any =
          (verticalPosition - windowInnerHeight / 3 - windowInnerHeight / 3) /
          (windowInnerHeight / 3);
        // triggerElement.style.opacity = ((0.5 - newOpacity) < 0) ? 0 : (0.5 - newOpacity);
        // triggerElement.style.display = ((0.5 - newOpacity) < 0) ? "none" : "block";

        triggerElement.style.opacity =
          0.8 - newOpacity < 0 ? 0 : 0.8 - newOpacity;
        triggerElement.style.display = 0.8 - newOpacity < 0 ? 'none' : 'block';

        if (verticalPosition < 80) {
          if (!messageBubble[0].classList.contains('text-white')) {
            messageBubble[0].classList.add('text-white');
          }
        } else {
          messageBubble[0].classList.remove('text-white');
        }

        // ToDo: -0.2 on mobile

        //
        // Animate message bubble disappearance
        //
        messageBubble[0].style.opacity =
          1 - newOpacity < 0 ? '0' : 1 - newOpacity + '';

        //
        // Animate the avatar fading
        //
        avatar[0].style['-webkit-mask-image'] =
          '-webkit-gradient(linear, left top, right bottom, from(rgba(0,0,0,' +
          (-0.9 - newOpacity) +
          ')), to(rgba(0,0,0,' +
          (-1.2 - newOpacity) +
          ')))';
        avatar[0].style['mask-image'] =
          'linear-gradient(to bottom, rgba(0,0,0,' +
          (-0.9 - newOpacity) +
          '), rgba(0,0,0,' +
          (-1.2 - newOpacity) +
          '))';
      } else {
        triggerElement.style['will-change'] = 'auto';
        triggerElement.style.opacity = 0;

        messageBubble[0].style.opacity = 0;

        avatar[0].style['-webkit-mask-image'] =
          '-webkit-gradient(linear, left top, right bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,0)))';
        avatar[0].style['mask-image'] =
          'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0))';
      }
    }
  }

  initEvolutionChart(): void {
    let chart = document.getElementById('collapsable-chart');
    if (window.innerWidth < 760) {
      // Smaller than tablet
      let chartIntersectionOptions = {
        root: this.defaultIntersectionOptions.root,
        rootMargin: `-1% 0px -70% 0px`,
        threshold: [0.5, 1.0],
      };

      let chartObserver = new IntersectionObserver(
        this.handleEvolutionIntersect.bind(this, chart),
        chartIntersectionOptions
      );
      chartObserver.observe(chart);
      this.intersectionObservers.push(chartObserver);
    } else {
      // For laptops +
      let scrollableTimeline = document.getElementById(
        'scrollable-timeline-cards'
      );

      let scrollableTimelineIntersectionOptions = {
        root: this.defaultIntersectionOptions.root,
        rootMargin: `0px 0px -10% 0px`,
        threshold: [0.5, 1.0],
      };

      let scrollableTimelineObserver = new IntersectionObserver(
        this.handleEvolutionIntersect.bind(this, chart),
        scrollableTimelineIntersectionOptions
      );
      scrollableTimelineObserver.observe(scrollableTimeline);
      this.intersectionObservers.push(scrollableTimelineObserver);
    }
  }

  handleEvolutionIntersect(this, chart, entries, observer) {
    let isAnimating = false;
    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      const isIntersecting = entry.isIntersecting;
      // console.log("isAnimating - real", isAnimating);

      // Scrolling down/up
      if (currentY < this.previousScrollY) {
        if (currentRatio > this.previousScrollRatio && isIntersecting) {
          // console.log("Scrolling down enter");
          // if (!isAnimating)

          // chart.classList.add("svg-collapsed");
          this.collapseChart();

          // setTimeout(function () {
          //   chart.classList.add("fixed-container");
          // }.bind(chart), 0.8);

          // isAnimating = true;
          // setTimeout(function () {
          //   isAnimating = false;
          //   console.log("isAnimating - should be", isAnimating);
          // }.bind(this), 5000);
        } else {
          // console.log("Scrolling down leave");
        }
      } else if (currentY > this.previousScrollY && isIntersecting) {
        if (currentRatio < this.previousScrollRatio) {
          // console.log("Scrolling up leave");
          // if (!isAnimating)

          // chart.classList.remove("svg-collapsed");
          this.expandChart();

          // setTimeout(function () {
          //   console.log(chart);
          //   chart.classList.remove("fixed-container");
          // }.bind(chart), 0.8);

          // isAnimating = true;
          // setTimeout(function () {
          //   isAnimating = false;
          //   console.log("isAnimating - should be", isAnimating);
          // }.bind(this), 5000);
        } else {
          // console.log("Scrolling up enter");
          // if (!isAnimating)

          // chart.classList.add("svg-collapsed");
          this.collapseChart();

          // setTimeout(function () {
          //   chart.classList.add("fixed-container");
          // }.bind(chart), 0.8);

          // isAnimating = true;
          // setTimeout(function () {
          //   isAnimating = false;
          //   console.log("isAnimating - should be", isAnimating);
          // }.bind(this), 5000);
        }
      }

      this.previousScrollY = currentY;
      this.previousScrollRatio = currentRatio;

      // if(entry.isIntersecting){
      //   console.log("came into view");
      //   if(!entry.target.classList.contains("svg-collapsed")){
      //       entry.target.classList.add("svg-collapsed");
      //     console.log("adding");

      //   }
      //   else{
      //     entry.target.classList.remove("svg-collapsed");
      //     console.log("removing");

      //   }

      // }
      // else{
      //   console.log("exited view");
      //   if(entry.target.classList.contains("svg-collapsed")){
      //     console.log("removing");
      //     entry.target.classList.remove("svg-collapsed");
      //   }
      // }
    });
    // observer.unobserve(document.querySelector("#evolution"));
  }

  timelineIsScrolling = false;
  async clickTimeline(event) {
    const collapseChart = document.getElementById('collapsable-chart');
    if (
      !this.timelineIsScrolling &&
      !collapseChart.classList.contains('svg-collapsed')
    ) {
      let projectID =
        event.path[1].id.split('-')[event.path[1].id.split('-').length - 1];
      if (!projectID) {
        projectID =
          event.path[0].id.split('-')[event.path[0].id.split('-').length - 1];
      }
      if (projectID) {
        this.timelineIsScrolling = true;

        let scrollableTimelineCards = document.getElementById(
          'timeline-project-cards'
        );
        let timelineAnchor = document.getElementById(
          'timeline-project-anchor-' + projectID
        );

        try {
          await this.smoothScrollTo(
            scrollableTimelineCards,
            'top',
            collapseChart.clientHeight * 1.2
          );

          await new Promise((resolve) => setTimeout(resolve, 200));
          timelineAnchor.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
          this.timelineIsScrolling = false;
        } catch (err) {
          console.log('Err', err);
          timelineAnchor.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
          this.timelineIsScrolling = false;
        }
      }
    }
  }

  hoverTimeline(event) {
    // if (!this.timelineIsScrolling && document.getElementById("collapsable-chart").classList.contains("svg-collapsed")) {
    let projectID =
      event.path[1].id.split('-')[event.path[1].id.split('-').length - 1];
    if (!projectID) {
      projectID =
        event.path[0].id.split('-')[event.path[0].id.split('-').length - 1];
    }
    if (projectID) {
      document
        .getElementById('timeline-project-anchor-' + projectID)
        .scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
    }
    // }
  }

  smoothScrollTo(element, direction = 'top', offset = 0) {
    let oldWindowPosition = Math.ceil(window.scrollY);
    let amount = element.offsetHeight;

    if (direction == 'left') {
      oldWindowPosition = Math.ceil(window.scrollY);
      amount = element.offsetHeight;
    } else if (direction == 'top') {
      oldWindowPosition = Math.ceil(window.scrollY);
      amount = Math.ceil(element.offsetTop + offset);
    }

    let scrollOptions = {};
    scrollOptions = {
      behavior: 'smooth',
    };
    scrollOptions[direction] = Math.ceil(amount);

    window.scrollTo(scrollOptions);

    // console.log(self.pageYOffset, oldWindowPosition, amount, window.scrollY, scrollOptions);
    // console.log(Math.ceil(self.pageYOffset + window.innerHeight / 2), oldWindowPosition + window.innerHeight / 2, Math.ceil(amount), Math.ceil(window.scrollY + window.innerHeight / 2));
    // console.log("!", Math.ceil(window.scrollY), Math.ceil(window.scrollY + window.innerHeight / 2));
    // console.log("@", Math.ceil(amount) * 0.95, Math.ceil(amount), Math.ceil(amount) * 1.05);

    return new Promise<void>((resolve, reject) => {
      const failed = setTimeout(() => {
        reject();
      }, 1500);

      const scrollHandler = () => {
        if (
          Math.ceil(window.scrollY) >= Math.ceil(amount) * 0.95 &&
          Math.ceil(window.scrollY) <= Math.ceil(amount) * 1.05
        ) {
          window.removeEventListener('scroll', scrollHandler);
          clearTimeout(failed);
          resolve();
        }
      };
      window.addEventListener('scroll', scrollHandler, {
        passive: true,
      });
      if (
        Math.ceil(window.scrollY) >= Math.ceil(amount) * 0.95 &&
        Math.ceil(window.scrollY) <= Math.ceil(amount) * 1.05
      ) {
        window.removeEventListener('scroll', scrollHandler);
        clearTimeout(failed);
        resolve();
      }

      // console.log("AA");
      // console.log(oldWindowPosition + Math.ceil(amount));
      // console.log(`(${Math.ceil(self.pageYOffset)} >= ${(oldWindowPosition + Math.ceil(amount))*0.9}) && (${Math.ceil(self.pageYOffset)} <= ${(oldWindowPosition + Math.ceil(amount))*1.1})`);
      // if ((Math.ceil(self.pageYOffset) >= (oldWindowPosition + Math.ceil(amount))*0.9) && (Math.ceil(self.pageYOffset) <= (oldWindowPosition + Math.ceil(amount))*1.1)) {
      // console.log("BB");
      // window.removeEventListener("scroll", scrollHandler);
      // clearTimeout(failed);
      // resolve();
      // }
    });
  }

  scrollTimeline(event) {
    // console.log(event);
    // console.log(event.path[1].id, event.path[1].id.split("-"), event.path[1].id.split("-").length);
    // console.log(document.getElementById("timeline-project-card-"+projectID));
    if (
      event.type == 'click' ||
      (
        event.type == 'mouseover' &&
        document.getElementById('collapsable-chart')
      ).classList.contains('svg-collapsed')
    ) {
      let projectID =
        event.path[1].id.split('-')[event.path[1].id.split('-').length - 1];
      if (!projectID) {
        projectID =
          event.path[0].id.split('-')[event.path[0].id.split('-').length - 1];
      }
      if (projectID) {
        if (event.type == 'click') {
          this.smoothScrollTo(
            document.getElementById('timeline-project-cards'),
            'top'
          );
          document.getElementById('timeline-project-cards').scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
          setTimeout(() => {
            document
              .getElementById('timeline-project-anchor-' + projectID)
              .scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              });
          }, 1500);
        } else {
          document
            .getElementById('timeline-project-anchor-' + projectID)
            .scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start',
            });
        }
      }
    }
  }

  expandChart() {
    let chart = document.getElementById('collapsable-chart');

    chart.classList.remove('svg-collapsed');
  }

  collapseChart() {
    let chart = document.getElementById('collapsable-chart');

    chart.classList.add('svg-collapsed');
  }

  toggleCollapsingOfChart(event) {
    let chart = document.getElementById('collapsable-chart');
    if (chart.classList.contains('svg-collapsed')) {
      chart.classList.remove('svg-collapsed');

      document.getElementById('evolution').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    } else {
      chart.classList.add('svg-collapsed');

      document.getElementById('timeline-project-cards').scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  handleDrawingIntersect(
    drawingFunction,
    drawingInstructions,
    entries,
    observer
  ) {
    // console.log("Drawing init ");

    entries.forEach((entry) => {
      if (entry.intersectionRect.height > 0) {
        // console.log("Drawing ", drawingInstructions, entries, observer);

        let drawnElement = document.getElementById(
          drawingInstructions.elementID
        );

        drawingFunction(
          drawnElement,
          drawingInstructions.svgModel,
          drawingInstructions.strokeColor,
          100
        );
        observer.unobserve(
          document.getElementById(drawingInstructions.elementID)
        );
      }
    });
  }

  drawAround(element, svgModel, strokeColor, animationDelay = 0): void {
    // console.log(element, element.getBoundingClientRect());
    // console.log(element.scrollWidth, element.scrollHeight);

    let position = element.getBoundingClientRect();
    var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let parentPaddingTop = parseInt(
      window.getComputedStyle(element, null).getPropertyValue('padding-left')
    );
    let parentPaddingLeft = parseInt(
      window.getComputedStyle(element, null).getPropertyValue('padding-top')
    );

    svg.style.position = 'absolute';
    svg.style.width = element.scrollWidth - parentPaddingLeft * 2 + 'px';
    svg.style.height = element.scrollHeight + parentPaddingTop * 2 + 'px';
    // svg.style.width = "100%";
    // svg.style.height = "100%";
    svg.style.left = parentPaddingLeft + '';
    svg.style.top = -parentPaddingTop + '';
    svg.style.opacity = '0.9';

    function ptToX(pt): any {
      return parseInt('' + (pt / 100) * position.width);
    }

    function ptToY(pt): any {
      return parseInt('' + (pt / 100) * position.height);
    }

    const iconPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );

    svg.setAttribute(`fill`, `none`);
    svg.setAttribute(
      `viewBox`,
      `0 0 ${parseInt(position.width)} ${parseInt(position.height)}`
    );
    svg.setAttribute(`preservePerspective`, `none`);
    svg.setAttribute(`stroke`, strokeColor);

    let attributePath = '';

    function createQuadraticPath() {
      let path = '';
      let start = [40, 20];

      let shape1 = [
        {
          control: [20, 0],
          end: [12.5, 12.5],
        },
        {
          control: [4, 26],
          end: [11.8, 61],
        },
        {
          control: [20, 100],
          end: [55, 99],
        },
        {
          control: [99, 99],
          end: [99, 50],
        },
      ];

      let end = [70, 1];

      path = `M ${ptToX(start[0])} ${ptToY(start[1])}`;

      for (let point of shape1) {
        path += ` Q ${ptToX(point.control[0])} ${ptToY(
          point.control[1]
        )}, ${ptToX(point.end[0])} ${ptToY(point.end[1])}`;
      }

      path += `T ${ptToX(end[0])} ${ptToY(end[1])}`;
      return path;
    }
    attributePath = createQuadraticPath();

    function createCubicPath(inputSvg) {
      let path = '';
      let points = inputSvg.split('C');

      let start = [
        inputSvg.split('C')[0].split('M')[1].split(' ')[0],
        inputSvg.split('C')[0].split('M')[1].split(' ')[1],
      ];
      points.shift();

      path = `M${ptToX(start[0])} ${ptToY(start[1])}`;

      for (let point of points) {
        let coordinates = point.split(' ');
        path += `C${ptToX(coordinates[0])} ${ptToY(coordinates[1])} ${ptToX(
          coordinates[2]
        )} ${ptToY(coordinates[3])} ${ptToX(coordinates[4])} ${ptToY(
          coordinates[5]
        )}`;
      }

      return path;
    }
    attributePath = createCubicPath(svgModel);

    iconPath.setAttribute(`d`, attributePath);

    iconPath.setAttribute('stroke-linecap', 'round');
    iconPath.setAttribute('stroke-linejoin', 'round');
    iconPath.setAttribute('stroke-width', '2');

    iconPath.style['stroke-dasharray'] = 3000;
    iconPath.style['stroke-dashoffset'] = 0;
    iconPath.style['animation'] = 'dash 0.8s linear';
    svg.style[`z-index`] = `-1`;

    if (animationDelay) {
      setTimeout(
        function () {
          svg.appendChild(iconPath);
          element.appendChild(svg);
        }.bind(svg, iconPath),
        animationDelay
      );
    } else {
      svg.appendChild(iconPath);
      element.appendChild(svg);
    }
  }

  initMenuTracking(): void {
    let sectionIDs = ['intro', 'projects', 'evolution', 'what-i-do', 'about'];
    let intersectionOptions = {
      root: this.defaultIntersectionOptions.root,
      rootMargin: `0px 0px 0px 0px`,
      threshold: [0.15, 0.5, 1.0],
    };
    let intersectionObserver = new IntersectionObserver(
      this.handleMenuTracking,
      intersectionOptions
    );

    for (let sectionID of sectionIDs) {
      let element = document.getElementById(sectionID);
      // console.log("element", element);
      intersectionObserver.observe(element);
    }
    this.intersectionObservers.push(intersectionObserver);
  }

  handleMenuTracking(entries, observer): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // console.log(entry.target.id, "is IN", entry.intersectionRatio, "%")
        if (entry.target.id == 'intro') {
          document.getElementById('menu/intro').classList.remove('active');
          document
            .getElementById('scroll-invitation-arrow')
            .classList.remove('hidden');
          document.getElementById('contact-bubble').classList.remove('visible');
          document.getElementById('theme-toggler').classList.remove('visible');
          document
            .getElementById('menu/intro')
            .classList.remove('mobile-active');
        } else {
          // console.log("removing menu");
          // console.log("0. intersectionRatio", entry.intersectionRatio);
          // document.getElementById("menu/intro").style.zIndex = "2";
          document.getElementById('menu/intro').classList.add('visible');
          document.getElementById('contact-bubble').classList.add('visible');
          document.getElementById('theme-toggler').classList.add('visible');
          document
            .getElementById('scroll-invitation-arrow')
            .classList.add('hidden');

          // console.log("1. intersectionRatio", entry.intersectionRatio);
          document
            .getElementById('menu/' + entry.target.id)
            .classList.add('active');
        }
      } else {
        // console.log(entry.target.id, " is OUT");
        if (entry.target.id == 'intro') {
          document.getElementById('menu/intro').classList.add('active');
        } else {
          if (entry.target.id == 'projects') {
            // console.log("adding menu");
            // console.log("3. intersectionRatio", entry.intersectionRatio);
            // document.getElementById("menu/intro").style.zIndex = "0";
            document.getElementById('menu/intro').classList.remove('visible');
            document
              .getElementById('menu/intro')
              .classList.remove('mobile-active');
          }
          // console.log("4. intersectionRatio", entry.intersectionRatio);
          document
            .getElementById('menu/' + entry.target.id)
            .classList.remove('active');
        }
      }
    });
  }

  toggleMobileMenu(event?): void {
    // console.log("event", event);
    // 992 is current equivalent of map-get($grid-breakpoints, lg)
    if (window.innerWidth <= 992) {
      let menuItem = document.getElementById('menu/intro');
      if (menuItem.classList.contains('mobile-active')) {
        menuItem.classList.remove('mobile-active');
      } else {
        menuItem.classList.add('mobile-active');
      }
    }
  }

  closeMobileMenu(event?): void {
    let menuItem = document.getElementById('menu/intro');
    menuItem.classList.remove('mobile-active');
  }

  initTimeline(): void {
    //
    // Calculate max time interval
    //
    let startDate = 3000;
    let endDate = 0;
    let totalMonthsInterval = 0;

    for (let projectIndex in this.timelineProjects) {
      let projectStart = parseInt(
        this.timelineProjects[projectIndex].startDate
      );
      let projectEnd = parseInt(this.timelineProjects[projectIndex].endDate);

      if (projectStart < startDate) {
        startDate = projectStart;
      }

      if (projectEnd > endDate) {
        endDate = projectEnd;
      }
    }

    //
    // Put the years on the axis
    // Do it manually for the first and last year
    //
    this.years[0] = startDate;
    totalMonthsInterval += 12;

    for (let year = startDate + 1; year < endDate; year++) {
      this.years.push("'" + (year + '').slice(-2));

      // Only count the interval between years
      totalMonthsInterval += 12;
    }
    this.years.push(endDate);

    //
    // Calculate lengths for svgs
    //
    for (let index in this.timelineProjects) {
      let projectMonthsInterval =
        (parseInt(this.timelineProjects[index].endDate) -
          parseInt(this.timelineProjects[index].startDate)) *
        12;
      let projectWidth = (projectMonthsInterval / totalMonthsInterval) * 100;

      this.timelineProjects[index]['style'] = {};
      this.timelineProjects[index]['style']['max-width'] = projectWidth + '%';
      this.timelineProjects[index]['style']['flex'] =
        '0 0 ' + projectWidth + '%';

      if (parseInt(index) == 0) {
        this.timelineProjects[index]['style']['left'] = '0%';
        this.timelineProjects[index]['translated'] = 0;
      } else {
        let differenceBetweenLastProjectAndCurrent =
          parseInt(this.timelineProjects[parseInt(index) - 1].endDate) -
          parseInt(this.timelineProjects[index].startDate);

        // console.log("projectWidth", projectWidth);
        // console.log("projectMonthsInterval", projectMonthsInterval);
        // console.log("differenceBetweenLastProjectAndCurrent", differenceBetweenLastProjectAndCurrent);
        // console.log("projectWidth / projectMonthsInterval", projectWidth / projectMonthsInterval);

        let offsetInRelativePercentages =
          (differenceBetweenLastProjectAndCurrent * 12 * projectWidth) /
          projectMonthsInterval;

        // console.log(parseInt(index), "offsetInRelativePercentages", offsetInRelativePercentages);

        // console.log("last one ", this.timelineProjects[parseInt(index)-1]["translated"]);
        // console.log("current one ", offsetInRelativePercentages);
        // console.log("sum ", parseFloat(this.timelineProjects[parseInt(index)-1]["translated"]+ offsetInRelativePercentages));
        this.timelineProjects[index]['translated'] = parseFloat(
          this.timelineProjects[parseInt(index) - 1]['translated'] +
            offsetInRelativePercentages
        );
        // this.timelineProjects[index]["style"]["transform"] = `translateX(-${this.timelineProjects[index]["translated"]}%)`;

        // this.timelineProjects[index]["style"]["left"] = `-${offsetInRelativePercentages}%`;
        this.timelineProjects[index]['style'][
          'left'
        ] = `-${this.timelineProjects[index]['translated']}%`;

        // this.timelineProjects[index]["style"]["left"] = `0%`;
      }

      // console.log(this.timelineProjects);
      // console.log(this.timelineProjects[index].name, projectWidth*100, "%");
    }
    // console.log(this.timelineProjects);

    //
    //
    //
  }

  async goToProjects(): Promise<void> {
    // document.getElementById("projects").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    let elem = window.innerHeight;
    let currentPosition = window.scrollY;
    let scrollDuration = 1000;
    let scrollTo = window.innerHeight;
    let anchorHeightAdjust = 30;

    //
    // TRY 15
    //
    function smoothScrollBy(amount) {
      let oldWindowPosition = Math.ceil(window.scrollY);
      window.scrollBy({
        top: Math.ceil(amount),
        behavior: 'auto',
      });
      // console.log(self.pageYOffset, oldWindowPosition, amount, window.scrollY);
      // console.log(Math.ceil(self.pageYOffset), oldWindowPosition, Math.ceil(amount), Math.ceil(window.scrollY));

      return new Promise<void>((resolve, reject) => {
        const failed = setTimeout(() => {
          reject();
        }, 2000);

        const scrollHandler = () => {
          if (
            Math.ceil(self.pageYOffset) >=
              (oldWindowPosition + Math.ceil(amount)) * 0.9 &&
            Math.ceil(self.pageYOffset) <=
              (oldWindowPosition + Math.ceil(amount)) * 1.1
          ) {
            window.removeEventListener('scroll', scrollHandler);
            clearTimeout(failed);
            resolve();
          }
        };
        window.addEventListener('scroll', scrollHandler, {
          passive: true,
        });

        // console.log("AA");
        // console.log(oldWindowPosition + Math.ceil(amount));
        // console.log(`(${Math.ceil(self.pageYOffset)} >= ${(oldWindowPosition + Math.ceil(amount))*0.9}) && (${Math.ceil(self.pageYOffset)} <= ${(oldWindowPosition + Math.ceil(amount))*1.1})`);
        // if ((Math.ceil(self.pageYOffset) >= (oldWindowPosition + Math.ceil(amount))*0.9) && (Math.ceil(self.pageYOffset) <= (oldWindowPosition + Math.ceil(amount))*1.1)) {
        // console.log("BB");
        // window.removeEventListener("scroll", scrollHandler);
        // clearTimeout(failed);
        // resolve();
        // }
      });
    }

    //
    // TRY 13
    //
    document.documentElement.style.scrollBehavior = 'auto';

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    let increment = 2;
    (async () => {
      while (window.scrollY < elem) {
        // async function scrollingLoop(timestamp) {
        // if (window.scrollY < elem) {
        if (window.scrollY + increment >= elem) {
          increment = elem - window.scrollY;
        }

        try {
          await smoothScrollBy(increment);
          // increment = increment * 1.0035;
          if (window.scrollY < elem / 9) {
            increment = increment * 1.06;
          } else if (window.scrollY < elem / 4) {
            // increment = increment * 1.028;
            increment = increment * 0.95;
          } else {
            increment = increment * 1.1;
          }
          // window.requestAnimationFrame(scrollingLoop);
        } catch (err) {
          console.log('Err', err);
          return;
        }

        // } else return;
      }

      // await delay(1);
      // window.requestAnimationFrame(scrollingLoop);

      document.documentElement.style.scrollBehavior = 'smooth';
    })();

    // // //
    // // // HDAIJSDKSPAIUGYSAHSJIAYTSYDGHAJSIOAGYUSHJ
    // // //

    //
    // TRY 14
    //
    // document.documentElement.style.scrollBehavior = "auto";

    // function smoothScroll(amount) {
    //   let oldWindowPosition = window.scrollY;
    //   window.scrollBy({
    //     top: Math.ceil(amount),
    //     behavior: 'auto'
    //   });

    //   return new Promise < void > ((resolve, reject) => {
    //     const failed = setTimeout(() => {
    //       reject();
    //     }, 2000);

    //     const scrollHandler = () => {
    //       if (self.pageYOffset === oldWindowPosition + Math.ceil(amount)) {
    //         window.removeEventListener("scroll", scrollHandler);
    //         clearTimeout(failed);
    //         resolve();
    //       }
    //     };
    //     if (self.pageYOffset === oldWindowPosition + Math.ceil(amount)) {
    //       clearTimeout(failed);
    //       resolve();
    //     }
    //   });
    // }

    // //
    // // TRY 13
    // //

    // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    // let increment = 1;
    // (async () => {
    //   while (window.scrollY < elem) {
    //     if (window.scrollY + increment >= elem) {
    //       increment = elem - window.scrollY;
    //     }

    //     try {
    //       await smoothScroll(increment)
    //       increment = increment * 1.0035;
    //       if (window.scrollY > elem / 4) {
    //         increment = increment * 1.028;
    //       }
    //     } catch (err) {
    //       console.log("Err", err)
    //     }
    //     // document.documentElement.scrollTop += 100;
    //     // console.log(increment,"/",elem);

    //     await delay(1);
    //   }
    //   document.documentElement.style.scrollBehavior = "smooth";
    // })();

    // // //
    // // // HDAIJSDKSPAIUGYSAHSJIAYTSYDGHAJSIOAGYUSHJ
    // // //

    //
    // TRY 12
    //

    // if ($(window).scrollTop() != scrollTo) {
    //   $('html, body').stop().animate({
    //     scrollTop: scrollTo
    //   },1500);
    // }

    //
    // TRY 11
    //

    // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    // (async () => {
    //   while (document.documentElement.scrollTop <= document.body.scrollHeight - 100) {

    //     let increment = (document.documentElement.scrollTop||document.body.scrollHeight)*0.8;

    //     console.log(increment)
    //     // window.scrollBy(0, increment);
    //     document.documentElement.scrollTop += 300;
    //     await delay(200);
    //   }
    // })();

    //
    // TRY 10
    //

    // // t = current time
    // // b = start value
    // // c = change in value
    // // d = duration
    //  function easeInOutQuad(t, b, c, d) {
    //   t /= d/2;
    //   if (t < 1) return c/2*t*t + b;
    //   t--;
    //   return -c/2 * (t*(t-2) - 1) + b;
    //   };

    //   function easeInOutQuint(t) { return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t }

    //   async function scroll_To(element, to, duration) {
    //   var start = document.documentElement.scrollTop,
    //       change = to - start,
    //       currentTime = 0,
    //       increment = 20;

    //   var animateScroll = async function(){
    //     console.log(increment)
    //       currentTime += increment;
    //       console.log(change);
    //       var val = easeInOutQuad(currentTime, start, change, duration);
    //       document.documentElement.scrollTop += val;
    //       if(currentTime < duration) {
    //         await delay(increment);
    //         animateScroll();
    //       }
    //   };
    //   await delay(increment);
    //   animateScroll();
    // }
    // scroll_To(window, scrollTo, 1000);

    //
    // TRY 9
    //
    // document.documentElement.scrollTop = 1000;

    //
    // TRY 8
    //

    // linear(default), easeInQuad, easeOutQuad, easeInOutQuad, easeInCubic, easeOutCubic, easeInOutCubic, easeInQuart, easeOutQuart, easeInOutQuart, easeInQuint, easeOutQuint, easeInOutQuint

    //   easyScroll({
    //     'scrollableDomEle': window,
    //     'direction': 'bottom',
    //     'duration': 400,
    //     'easingPreset': 'easeInOutQuint',
    //     'scrollAmount': scrollTo
    // });

    //
    // TRY 7
    //

    // // t = current time
    // // b = start value
    // // c = change in value
    // // d = duration
    //  function easeInOutQuad(t, b, c, d) {
    //   t /= d/2;
    //   if (t < 1) return c/2*t*t + b;
    //   t--;
    //   return -c/2 * (t*(t-2) - 1) + b;
    //   };

    // function scroll_To(element, to, duration) {
    //   var start = document.documentElement.scrollTop,
    //       change = to - start,
    //       currentTime = 0,
    //       increment = 20;

    //   var animateScroll = function(){
    //     console.log(increment)
    //       currentTime += increment;
    //       var val = easeInOutQuad(currentTime, start, change, duration);
    //       document.documentElement.scrollTop = val;
    //       if(currentTime < duration) {
    //           setTimeout(animateScroll, increment);
    //       }
    //   };
    //   animateScroll();
    // }
    // scroll_To(window, document.getElementById("about"), 3000);

    //
    // TRY 6
    //

    // if (scrollTo > anchorHeightAdjust) {
    //   scrollTo = scrollTo - anchorHeightAdjust;
    // }

    // // Declarations

    // var cosParameter = (window.pageYOffset - scrollTo) / 2,
    //   scrollCount = 0,
    //   oldTimestamp = window.performance.now();

    // function step(newTimestamp) {
    //   var tsDiff = newTimestamp - oldTimestamp;

    //   // Performance.now() polyfill loads late so passed-in timestamp is a larger offset
    //   // on the first go-through than we want so I'm adjusting the difference down here.
    //   // Regardless, we would rather have a slightly slower animation than a big jump so a good
    //   // safeguard, even if we're not using the polyfill.

    //   if (tsDiff > 100) {
    //     tsDiff = 30;
    //   }

    //   scrollCount += Math.PI / (scrollDuration / tsDiff);

    //   // As soon as we cross over Pi, we're about where we need to be

    //   if (scrollCount >= Math.PI) {
    //     return;
    //   }

    //   var moveStep = Math.round(scrollTo + cosParameter + cosParameter * Math.cos(scrollCount));
    //   console.log("moveStep", moveStep);

    //   window.scrollTo(0, moveStep);
    //   oldTimestamp = newTimestamp;
    //   window.requestAnimationFrame(step);
    // }

    // window.requestAnimationFrame(step);

    //
    // TRY 5 - WORKS BUT NEEDS EASING
    //

    // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    // (async () => {
    //   while (document.documentElement.scrollTop <= document.body.scrollHeight - 100) {
    //     window.scrollTo(0, document.documentElement.scrollTop + 100);
    //     // document.documentElement.scrollTop += 100;
    //     await delay(30);
    //   }
    // })();

    //
    // TRY 4
    //

    // let scrollTimeout;
    // scroll_to(elem, scrollTimeout);
    // async function scroll_to(elem, scrollTimeout) {
    //   console.log(elem, window.scrollY);

    //   let increment = 300;
    //   if (window.scrollY >= elem) {
    //     return;
    //   }
    //   if(window.scrollY+increment >= elem) {
    //     increment =  elem - window.scrollY;
    //   }
    //   // let goal = ((window.scrollY + elem*0.1)>=elem)?(elem):(window.scrollY + elem*0.1);
    //   // console.log(goal);
    //   // window.scrollTo({
    //   //   top: goal,
    //   //   behavior: 'auto'
    //   // });
    //   // document.documentElement.scrollTop = goal;

    //   // document.documentElement.scrollBy({
    //   //   top: 41,
    //   //   behavior: 'auto'
    //   // });

    //   document.documentElement.scrollTop += increment;
    //   clearTimeout(scrollTimeout);

    //   // await delay(30);
    //   scrollTimeout = setTimeout(scroll_to, 20, elem, scrollTimeout);
    // }

    //
    // TRY 3
    //

    // scroll_to(elem);

    // function scroll_to(elem) {

    //   console.log(elem, window.scrollY);

    //   if (elem <= window.scrollY) {
    //     return;
    //   }
    //   // let goal = ((window.scrollY + elem*0.1)>=elem)?(elem):(window.scrollY + elem*0.1);
    //   // console.log(goal);
    //   // window.scrollTo({
    //   //   top: goal,
    //   //   behavior: 'auto'
    //   // });
    //   // document.documentElement.scrollTop = goal;
    //   document.documentElement.scrollBy(0, 5);
    //   setTimeout(scroll_to, 2, elem);

    // }

    //
    // TRY 2
    //

    // while (window.scrollY <= window.innerHeight) {

    //   if (i%20000 == 0){
    //     console.log(i);
    //     console.log("pl", window.scrollY, window.innerHeight);
    //     window.scrollTo({
    //       top: window.scrollY + 100,
    //       behavior: 'smooth'
    //     });
    //   }
    //   i++;
    // }

    //
    // TRY 1
    //

    // function scroll_to(elem, pos) {

    //   let y = elem.scrollTop;
    //   console.log(y);
    //   y += Math.round((pos - y) * 0.3);
    //   if (Math.abs(y - pos) <= 2) {
    //     elem.scrollTop = pos;
    //     return;
    //   }
    //   elem.scrollTop = y;
    //   setTimeout(scroll_to, 40, elem, pos);

    // }
  }

  scrollToProperProject(): void {
    let previousRoute = this.routingState.getPreviousUrl();
    // console.log(previousRoute);
    if (previousRoute != '/') {
      let projectID = -1;
      // console.log("comes from", previousRoute);

      switch (previousRoute.split('#')[0]) {
        case '/izibac': {
          console.log('izi');
          projectID = 0;
          break;
        }
        case '/peteco': {
          console.log('peteco');
          projectID = 1;
          break;
        }
        case '/scoala-de-business': {
          console.log('scoala-de-business');
          projectID = 2;
          break;
        }
      }
      console.log(projectID);
      if (projectID != -1) {
        setTimeout(
          function () {
            console.log('scrolling back');
            document
              .getElementById('project-card-container-' + projectID)
              .scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            document.getElementById('menu/intro').classList.add('visible');
          }.bind(document),
          100
        );
      } else {
        // console.log("Not a project route")
      }
    } else {
      // console.log("first load");
    }
  }
}
