import {
  Component
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import {
  AngularFireAnalytics
} from '@angular/fire/analytics';
import {
  Meta
} from '@angular/platform-browser';

import {
  RoutingStateService
} from './services/routing-state/routing-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public router: Router, analytics: AngularFireAnalytics, private meta: Meta, private routingState: RoutingStateService) {
    this.routingState.loadRouting();

    // this.router.events.subscribe(event => {
    //   if(event instanceof NavigationEnd){
    //       gtag('config', 'G-JDDXDLGEBF', 
    //             {
    //               'page_path': event.urlAfterRedirects
    //             }
    //            );
    //    }
    // });
  };

  title = 'eduard-lache';
  currentTheme = "light-theme";


  ngOnInit(): void {
    this.meta.addTag({
      name: 'viewport',
      content: 'minimum-scale=1, width=device-width, initial-scale=1'
    });
  }

  ngAfterViewInit(): void {

    // Add the theme toggler on every page except home
    let url = window.location.href;
    let parsedUrl = url.split("/");
    if (parsedUrl.length >= 4 && parsedUrl[parsedUrl.length - 1] != "") {
      document.getElementById("theme-toggler").classList.add("visible");
    }
  }

  toggleTheme(): void {
    if (this.currentTheme == "light-theme") {
      this.currentTheme = "dark-theme";
        document.getElementsByTagName("html")[0].classList.add("dark-theme");
        document.getElementsByTagName("html")[0].classList.remove("light-theme");
    } else {
      this.currentTheme = "light-theme";
        document.getElementsByTagName("html")[0].classList.remove("dark-theme");
        document.getElementsByTagName("html")[0].classList.remove("light-theme");
    }
  }
}



// <script async src="https://www.googletagmanager.com/gtag/js?id=G-JDDXDLGEBF"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-JDDXDLGEBF');
// </script>
