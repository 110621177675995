<div class="">
  <div>
    <bg-shapes class="izibac-circle"></bg-shapes>
    <custom-cursor id="cursor-container"></custom-cursor>

    <div class="container sticky" style="top: 20px; z-index: 2;">
      <div class="d-block" style="position: relative ">
        <div class="nav-bar d-flex justify-content-end align-items-center sticky">
          <div>

            <div>
              <div class="back-button animate-on-hover d-flex justify-content-between align-items-center float-right"
                (click)="goHome()">
                <icon-long-arrow-back></icon-long-arrow-back>
                <span class="ml-3">Home</span>
              </div>
            </div>

            <div class="float-right">
              <div id="show-prototype-button"
                class="d-flex d-lg-none back-button slide-icon animate-on-hover mt-3 justify-content-between align-items-center float-right"
                (click)="showPrototype()" (mouseover)="preparePrototypeTransition()">
                <span>Show</span>
                <icon-slide-open class="ml-2"></icon-slide-open>
              </div>

              <div id="hide-prototype-button"
                class="d-none d-lg-flex back-button slide-icon animate-on-hover mt-3 justify-content-between align-items-center float-right"
                (click)="hidePrototype()">
                <span>Hide&nbsp;</span>
                <icon-slide-close class="ml-2"></icon-slide-close>
              </div>
              <div
                class="d-none d-lg-flex back-button fullscreen-icon animate-on-hover mt-3 mr-2 justify-content-between align-items-center float-right"
                (click)="openPrototype()" (mouseover)="preparePrototypeTransition()">
                <icon-internal-fullscreen></icon-internal-fullscreen>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="container mb-6 d-flex">
      <div class="title">iziBAC</div>
    </div>

    <div class="container d-flex flex-wrap" style="z-index: 1;">
      <div tabindex=-1 (click)="openPrototype()" (mouseover)="preparePrototypeTransition()" class="mt-4 mr-4">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Figma prototype
          <icon-internal-fullscreen class="ml-2"></icon-internal-fullscreen>
        </button>
      </div>
      <a tabindex=-1 class="mt-4 mr-4" href="https://www.izibac.ro" target="_blank" rel="noopener"
        aria-label="iziBAC website">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Website
          <icon-external-link class="ml-2"></icon-external-link></button>
      </a>
      <a tabindex=-1 class="mt-4 mr-4"
        href="https://play.google.com/store/apps/details?id=com.izibac.educational&hl=en&gl=US" target="_blank"
        rel="noopener" aria-label="iziBAC on Google Play">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Google Play
          <icon-external-link class="ml-2"></icon-external-link></button>
      </a>
      <a tabindex=-1 class="mt-4 mr-4" href="https://apps.apple.com/us/app/izibac/id1496498958" target="_blank"
        rel="noopener" aria-label="iziBAC on App Store">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>App Store
          <icon-external-link class="ml-2"></icon-external-link></button>
      </a>
    </div>

    <div class="container d-block d-lg-flex">
      <div class="">
        <div id="projects" class="projects">
        </div>

          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              About project
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <p class="article-text">
                  Passing the Baccalaureate exam in Romania is a huge challenge for high-schoolers and the provability
                  rates are low.
                </p>
                <br>
                <p class="article-text">
                  iziBAC is a gamified mobile application that helps pupils prepare for the exam in a fun and gamified
                  way.
                </p>
                <br>
                <div class="article-text">
                  <b>Some skills I learned:</b>
                  <li>
                    Management
                  </li>
                  <li>
                    Product design
                  </li>
                  <li>
                    Product development
                  </li>
                  <li>
                    Business
                  </li>
                </div>
                <br>
              </div>
            </div>
          </div>
          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              Context
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <p class="article-text">
                  iziBAC is a project that we started from scratch. The idea came from high-schoolers that were afraid
                  of
                  the Baccalaureate exam and needed a fun way to learn.
                </p>
                <br>
                <div class="article-text">
                  <b>Our team:</b>
                  <li>
                    Raluca - CEO
                  </li>
                  <li>
                    Adrian - CMO
                  </li>
                  <li>
                    Me - CTO
                  </li>
                  <li>
                    Laurențiu - Lead Mobile developer
                  </li>
                </div>
                <br>
                <div class="article-text">
                  <b>Period:</b>
                  <li>
                    2017 - 2021
                  </li>
                </div>
              </div>
            </div>
          </div>

        <div class="row">
          <div class="col-12 col-md-6 col-xl-3 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC dashboard" title="iziBAC dashboard" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/dashboard-day.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC extracurricular" title="iziBAC extracurricular" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/extracurricula-day.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC leaderboard" title="iziBAC leaderboard" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/leaderboard-day.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC daily rewards" title="iziBAC daily rewards" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/daily-rewards.jpg" tabindex=0 />
            </div>
          </div>
        </div>

        <div class="">
          <div class="glass-card mt-0" tabindex=0>
            <div class="subtitle card-title color-primary">
              Approach
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <p class="article-text">
                  First step was to discuss with some of our 255.000 potential users. We organized one-to-one
                  interviews,
                  focus groups and surveys.
                </p>
                <p class="article-text">
                  After crunching the data we found out that the current learning methods are old, the content doesn’t
                  have
                  the structure they needed and wanted a different way to have the information being presented to them,
                  therefore they were not motivated to learn.
                </p>
                <p class="article-text">
                  Also, the private tutoring was expensive and inaccessible for many of them.
                </p>
              </div>
            </div>
          </div>

          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              Solution
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <p class="article-text">
                  The solution we found was to present the information to the teenager in a form they were currently
                  more
                  familiar with: games, video content, audio content, on their phone.
                </p>
                <p class="article-text">
                  Therefore, we designed a gamified, quiz-based mobile app.
                </p>
                <br>
              </div>
            </div>
          </div>

        </div>

          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              My responsibilities
            </div>
            <div class="card-content">
              <div class="d-flex flex-column h-100 justify-content-between">
                <div class="row mt-4">
                  <div class="article-text col-12 col-md-6">
                    <b>CTO & Co-Founder</b>
                    <p class="article-text">
                      Member of the board, business planning & strategy, designed and developed the first MVP
                    </p>
                  </div>
                  <div class="article-text col-12 mt-4 mt-md-0 col-md-6">
                    <b>Product management</b>
                    <p class="article-text">
                      Making sure the development priorities are aligned with strategy of future developments, with the
                      user’s
                      needs and their feedback
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="article-text col-12 col-md-6">
                    <b>UX & UI</b>
                    <li>Figma</li>
                    <li>Maze</li>
                    <li>User flows</li>
                  </div>
                  <div class="article-text col-12 mt-4 mt-md-0 col-md-6">
                    <b>Backend</b>
                    <li>Firebase</li>
                    <li>NodeJS</li>
                    <li>Express</li>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="article-text col-12 col-md-6">
                    <b>Infrastructure</b>
                    <li>Google Cloud platform</li>
                    <li>Firebase</li>
                  </div>
                  <div class="article-text col-12 mt-4 mt-md-0 col-md-6">
                    <b>Content Management platform</b>
                    <li>Angular</li>
                    <li>Firebase</li>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xl-3 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC market night" title="iziBAC market night" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/market-2-night.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC market offers" title="iziBAC market offers" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/market-1-day.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC market boosters" title="iziBAC market boosters" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/market-2-day.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="iziBAC market packs" title="iziBAC market packs" class="w-100 border-radius-20"
                src="./../../../assets/images/iziBAC/full-res/market-3-day.jpg" tabindex=0 />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xl-6">
            <div class="glass-card mt-0" tabindex=0>
              <div class="subtitle card-title color-primary">
                Monetization
              </div>
              <div class="card-content article-text">
                <div class="d-flex flex-column h-100 justify-content-between">
                  <li>Rewardable ads</li>
                  <li>Video content</li>
                  <li>Paid audio content</li>
                  <li>In-game shop with perks & boosters</li>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="glass-card mt-5 mt-xl-0" tabindex=0>
              <div class="subtitle card-title color-primary">
                Financing
              </div>
              <div class="card-content article-text">
                <div class="d-flex flex-column h-100 justify-content-between">

                  <li>Started from 0 € in 2017</li>
                  <li>Valued at 1.600.000 € in 2021</li>
                  <li>
                    <span style="hyphens: auto">
                      Received a 157.000 € investment as a crowd investment on:
                      <a href="https://investors.seedblink.com/projects/38" target="_blank" rel="noopener"
                        aria-label="Seedblink">Seedblink</a>
                    </span>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              Technologies
            </div>
            <div class="card-content article-text">
              <div class="d-flex h-100 justify-content-around flex-wrap">
                <div class="tech-item">
                  <img loading="lazy" alt="Firebase" title="Firebase" src="./../../../assets/logos/firebase.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Firebase</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img class="d-none-light d-block-dark" loading="lazy" src="./../../../assets/logos/nodejs-white.png" alt="NodeJs" title="NodeJs" />
                  <img class="d-none-dark" loading="lazy" src="./../../../assets/logos/nodejs.png" alt="NodeJs" title="NodeJs" />        
                  <div class="text-center w-100 mt-4">
                    <b>NodeJS</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="React native" title="React native" src="./../../../assets/logos/react-native.png" />
                  <div class="text-center w-100 mt-4">
                    <b>React native</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="Angular" title="Angular" src="./../../../assets/logos/angular.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Angular</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="Figma" title="Figma" class="p-4" src="./../../../assets/logos/figma-simple.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Figma</b>
                  </div>
                </div>

              </div>
            </div>
        </div>

          <div class="glass-card mt-5" tabindex=0>
            <div class="subtitle card-title color-primary">
              Results
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 card-content article-text">
                <ul>
                  <div class="d-flex flex-column h-100 justify-content-center">
                    <li>No. 1 in trending after first release</li>
                    <li>Over 60k total downloads</li>
                    <li>Over 10k monthly active users during exam period</li>
                  </div>
                </ul>
              </div>
              <div class="col-12 col-lg-6">
                <img loading="lazy" alt="Analytics" title="Analytics" class="w-100"
                  src="./../../../assets/images/iziBAC/full-res/analytics.png" />
              </div>
            </div>
          </div>

      </div>

    <div id="prototype-backdrop" (click)="closePrototype()">
      </div>
      <div id="prototype-container" class="prototype-container d-lg-block">
        <div class="mobile-frame ml-5 mt-5">
          <iframe id="prototype-iframe" style="height: 100%; width: 100%; border: none; margin-bottom: -5px;"
            width="100%" title="Figma prototype"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FnlZH6ENcJzH25Uk2dhWyLA%2F%255Bedd%255D-%255BiziBAC%255D-Official-prototype-screens%3Fnode-id%3D3720%253A71431%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D3720%253A71431%26hide-ui%3D1"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <rating-stars [project]="'izibac'"></rating-stars>

  <projects-navigator [previousProject]="'scoala-de-business'" [nextProject]="'peteco'"></projects-navigator>
  
  <a href="izibac#contact">
    <div id="contact-bubble" class="contact-bubble visible d-flex align-items-center justify-content-center animate-on-hover">
      <icon-write-envelope></icon-write-envelope>
    </div>
  </a>

  <app-footer id="contact"></app-footer>
</div>
