<custom-cursor id="cursor-container"></custom-cursor>
<bg-shapes></bg-shapes>

<div class="container sticky" style="top: 20px; z-index: 2">
  <div class="d-block" style="position: relative">
    <div class="nav-bar d-flex justify-content-end align-items-center sticky">
      <div>
        <div>
          <div
            class="back-button animate-on-hover d-flex justify-content-between align-items-center float-right"
            (click)="goHome()"
          >
            <icon-long-arrow-back></icon-long-arrow-back>
            <span class="ml-3">Home</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container pt-5 position-relative">
  <div class="title">Find my complete CV here</div>

  <div
    class="mt-5 mb-5 d-flex align-items-center justify-content-around flex-wrap gap-2"
  >
    <div>
      <div class="cv-loading-placeholder" (click)="downloadFullCv()">
        <a
          target="_blank"
          rel="noopener,noreferrer"
          href="./../../../assets/cv-files/Eduard-Lache-CV.pdf"
        >
          <div class="downloadable-cv">
            <!-- If you change the dimensions here, make sure to also change the -->
            <!-- dimensions of the placeholder in scss -->
            <img
              width="300px"
              src="./../../../assets/cv-files/full-cv-thumbnail.png"
            />
          </div>
        </a>
      </div>
      <!-- <h2 class="cv-title text-center mt-3">Full experience</h2> -->
    </div>

    <!-- <div>
    <div class="cv-loading-placeholder" (click)="downloadReactCv()">
      <a target="_blank" rel="noopener,noreferrer" href="./../../../assets/cv-files/Eduard-Lache-React.pdf">
        <div class="downloadable-cv">
        <img width="300px" src="./../../../assets/cv-files/react-cv-thumbnail.png"/>
      </div>
      </a>
    </div>
    <h2 class="cv-title text-center mt-3">React experience</h2>
  </div> -->
  </div>
</div>
<app-footer id="contact"></app-footer>
