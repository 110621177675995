<div class="container p-5">

  <form [formGroup]="roomCodeModel" (ngSubmit)="onFormSubmit()" #formDirective="ngForm">

    <mat-form-field class="col" appearance="fill">
      <mat-label>Room code</mat-label>
      <input matInput placeholder="Ex. 123" formControlName="roomCode">
    </mat-form-field>

    <div class="d-flex justify-content-around">
      <button type="submit" mat-button mat-flat-button color color="primary" class="action-button"
        (click)="becomeScreen()">Join as the screen</button>
      <button type="submit" mat-button mat-flat-button color color="warn" class="action-button"
        (click)="becomeController(0)">Join controller 1 (red)</button>
      <span class="dark-blue-theme">
        <button type="submit" mat-button mat-flat-button color color="primary" class="action-button"
          (click)="becomeController(1)">Join controller 2 (blue)</button>
      </span>
      <span class="dark-theme">
        <button type="submit" mat-button mat-flat-button color color="primary" class="action-button"
          (click)="becomeRealController()">Use real controller</button>
      </span>
    </div>
  </form>

  <app-screen [roomCode]="roomCode" *ngIf="showScreen"></app-screen>
  <app-controller [roomCode]="roomCode" [controllerID]="controllerID" *ngIf="showController"></app-controller>
  <app-real-controller [roomCode]="roomCode" [controllerID]="2" *ngIf="showRealController"></app-real-controller>
</div>
