import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss'],
})
export class CVComponent implements OnInit {
  constructor(
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  goHome() {
    this.analytics.logEvent('visit-site-from-cv-page');

    document.getElementsByTagName('html')[0].style.scrollBehavior = 'auto';

    this.router.navigateByUrl('');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
    document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth';
  }

  ngOnInit(): void {
    // console.log('viewed-cv-page');
    this.analytics.logEvent('viewed-cv-page');
  }

  downloadFullCv(): void {
    // console.log('downloaded-cv-full');
    this.analytics.logEvent('downloaded-cv-full');
  }

  downloadReactCv(): void {
    // console.log('downloaded-cv-react');
    this.analytics.logEvent('downloaded-cv-react');
  }
}
