import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'timeline-project-cards',
  templateUrl: './timeline-project-cards.component.html',
  styleUrls: ['./timeline-project-cards.component.scss']
})
export class TimelineProjectCardsComponent implements OnInit {
  @Input() projects;
  showLeftScrollControl = false;
  showRightScrollControl = true;
  scrollableArea;
  shouldScroll = true;
  removableBackdropClickHandler;
  backdrop;

  constructor() { }

  ngOnInit(): void {
    this.loadScrollableArea();
    this.backdrop = document.getElementById('global-backdrop');
    this.backdrop.addEventListener('mousedown', this.removableBackdropClickHandler = function () {
      const globalBackdrop = document.getElementById('global-backdrop');
      globalBackdrop.classList.remove('active-backdrop');
      // This removes all children and only keeps the first one (the close button)
      // @ts-ignore
      globalBackdrop.replaceChildren(globalBackdrop.firstChild);
    }, {
      passive: true
    });
  }

  loadScrollableArea(): void{
    this.scrollableArea = document.getElementById("scrollable-timeline-cards");
    let userClickedSubproject;
    let shouldOpenProject;
    let startX;
    let isDragging;
    let scrollLeft;

    this.scrollableArea.addEventListener('mousedown', (e) => {
      userClickedSubproject = true;
      shouldOpenProject = true;

      this.scrollableArea.classList.add('active');
      this.scrollableArea.style.userSelect = 'none';
      startX = e.pageX - this.scrollableArea.offsetLeft;
      scrollLeft = this.scrollableArea.scrollLeft;
    }, { passive: true });

    this.scrollableArea.addEventListener('mouseleave', () => {
      userClickedSubproject = false;
      isDragging = false;
      shouldOpenProject = false;
      this.scrollableArea.classList.remove('active');
    }, { passive: true });

    this.scrollableArea.addEventListener('mouseup', (e) => {
      userClickedSubproject = false;
      isDragging = false;
      this.scrollableArea.classList.remove('active');
    }, { passive: true });

    this.scrollableArea.addEventListener('mousemove', (e) => {
      isDragging = true;
      if (!userClickedSubproject) return;
      e.preventDefault();
      const x = e.pageX - this.scrollableArea.offsetLeft;
      const walk = (x - startX) * 1.6; //scroll-fast
      this.scrollableArea.scrollLeft = scrollLeft - walk;

      // Only open the picture if the user scrolled a little bit ( < |20px| )
      if (walk > -50 && walk < 50) {
        shouldOpenProject = true;
      } else {
        shouldOpenProject = false;
      }
    });

    let scrollableContainer: any = document.getElementById('scrollable-timeline-cards');
    if (scrollableContainer) {
      this.scrollableArea.addEventListener('scroll', function (e) {
        if (this.scrollableArea.scrollLeft < scrollableContainer.offsetWidth/4) {
            this.showLeftScrollControl = false;
        } else {
          if(this.scrollableArea.scrollLeft >= this.scrollableArea.scrollWidth - this.scrollableArea.clientWidth){
            this.showRightScrollControl = false;
          }
          else{
            this.showRightScrollControl = true;
          }
          this.showLeftScrollControl = true;
        }
      }.bind(this), { passive: true });
    }

  }

  smoothHorizontalScroll(element, amount) {
    let oldPosition = element.scrollLeft;
    element.scrollBy({
      left: Math.ceil(amount),
      behavior: 'auto'
    });

    return new Promise < void > ((resolve, reject) => {
      const failed = setTimeout(() => {
        reject();
      }, 2000);
      const scrollHandler = () => {
        if (amount < 0) {
        //   if ((Math.ceil(element.scrollLeft) >= (oldPosition + Math.ceil(amount)) * 0.9) && (Math.ceil(element.scrollLeft) <= (oldPosition + Math.ceil(amount)) * 1.1)) {
        //   }
          
          if (element.scrollLeft <= Math.ceil(amount)) {
            window.removeEventListener("scroll", scrollHandler);
            clearTimeout(failed);
            resolve();
          }
        } else {
          if (element.scrollLeft >= oldPosition + Math.ceil(amount)) {
            window.removeEventListener("scroll", scrollHandler);
            clearTimeout(failed);
            resolve();
          }
        }
      };
      if (element.scrollLeft >= oldPosition + Math.ceil(amount)) {
        clearTimeout(failed);
        resolve();
      }
    });
  }

  stopScroll(): void {
    this.shouldScroll = false;
  }

  startScroll(event, incrementSeed): void {
    event.preventDefault();
    event.stopPropagation();
    this.shouldScroll = true;

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    let increment = incrementSeed;
    let index = 1;

    function easingFunction(x) {
      let y;
      // Our index might go up to 1000 so we want to limit that 
      // Also, for the functions to work, x must be between [0,1]
      if (x > 1000) x = 1000;
      x = x / 1000;

      // easeOutSine
      // y = 1 - Math.sin((x * Math.PI) / 2);

      // easeInSine
      // y = 1 - Math.cos((x * Math.PI) / 2);

      // easeOutQuint
      // y = 1 - Math.pow(1 - x, 5);

      // easeOutCirc
      y = Math.sqrt(1 - Math.pow(x - 1, 2));

      // easeOutCubic
      // y = 1 - Math.pow(1 - x, 3);

      // Other working functions
      // y = (x === 0 ? 0 : Math.pow(2, 10 * x - 10))
      // y = (2 - Math.pow(1 - x, 1.675))
      return (1 + y);
    }
    let scrollableContainer: any = document.getElementById('scrollable-timeline-cards');

    (async () => {
      while (this.shouldScroll) {
        index++;
        if (this.scrollableArea.scrollLeft + increment >= this.scrollableArea.scrollWidth) {
          increment = incrementSeed;
        }
        try {
          await this.smoothHorizontalScroll(this.scrollableArea, increment);

          if (incrementSeed < 0) {
            // Scrolls towards start
            if (this.scrollableArea.scrollLeft < scrollableContainer.offsetWidth/2) {
              // Smooth out when comes back and remove the button
              increment = -6;
                this.showLeftScrollControl = false;

              // Ease scrolling 
              increment = increment * (this.scrollableArea.scrollLeft / scrollableContainer.offsetWidth);
              if (increment > -12) increment = -12;
              if (this.scrollableArea.scrollLeft == 0) {
                increment = 0;
                this.stopScroll();
              }
            } else if (increment < -40) {
              // Top the speed at 40 when moving to the start
              increment = -40;
            } else {
              increment = increment * easingFunction(index);
            }
          } else {
            // Scrolls towards end
            if (this.scrollableArea.scrollLeft > scrollableContainer.offsetWidth) {
              this.showLeftScrollControl = true;
            }
            if (increment > 8) {
              // Top the speed at 8 when moving left
              increment = 8;
            } else {
              increment = increment * easingFunction(index);
            }
          }
        } catch (err) {
          console.log("Err on scroll", err);
          this.stopScroll();
        }
        await delay(1);
      }
    })();
  }

  closeModal(){
    this.backdrop.classList.remove('active-backdrop');
    // This removes all children and only keeps the first one (the close button)
    // @ts-ignore
    this.backdrop.replaceChildren(this.backdrop.firstChild);
  }

  openModal(){
    this.backdrop.classList.add('active-backdrop');
  }

  viewSubProject(event, iframeURL, iframeParameters): void{
    let iframeContainer = document.createElement('div');
    iframeContainer.classList.add('preview-prototype-iframe-container')
    iframeContainer.style.width = iframeParameters.width.amount + iframeParameters.width.units;
    iframeContainer.style.height = iframeParameters.height.amount + iframeParameters.height.units;
    iframeContainer.style.minWidth = '300px';
    iframeContainer.style.maxWidth = '95vw';
    iframeContainer.style.maxHeight = '95vh';
    iframeContainer.style.minHeight = `500px`;
    iframeContainer.style.border = '3px solid #343a40';
    iframeContainer.style.backgroundColor = 'black';
    iframeContainer.style.position = 'absolute';
    iframeContainer.style.top = '50%';
    iframeContainer.style.left = '50%';
    iframeContainer.style.transform = 'translate(-50%, -50%)'
    iframeContainer.style.borderRadius = '32px';
    iframeContainer.style.overflow = 'hidden';
    
    let iframe = document.createElement('iframe');
    iframe.src = iframeURL;
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.style.border = 'none';

    iframeContainer.appendChild(iframe);
    this.backdrop.appendChild(iframeContainer);

    this.openModal();
  }
  
  ngOnDestroy(): void {
    window.removeEventListener('mousedown', this.removableBackdropClickHandler);
  }
}
