import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'projects-navigator',
  templateUrl: './projects-navigator.component.html',
  styleUrls: ['./projects-navigator.component.scss']
})
export class ProjectsNavigatorComponent implements OnInit {
  @Input() nextProject: string;
  @Input() previousProject: string;

  projects = {
    "izibac": {
      title: "iziBAC",
      logoPath: "./assets/logos/izibac.png",
      role: "Product Design, UI, UX, Backend dev",
      technologies: "Figma, NodeJS, Firebase",
      url: "/izibac",
    },
    "scoala-de-business": {
      title: "BCR Școala de business",
      logoPath: "./assets/logos/scoala-de-business.png",
      role: "Product, UI, UX, Frontend developer",
      technologies: "VanillaJS, Bootstrap",
      url: "/scoala-de-business",
    },
    "peteco": {
      title: "Peteco",
      logoPath: "./assets/logos/peteco.png",
      role: "UX & UI Design",
      technologies: "Figma, React native",
      url: "/peteco",
    },
    "framey": {
      title: "Framey",
      logoPath: "./assets/logos/framey.png",
      role: "Backend, Frontend developer",
      technologies: "Angular 2+, NodeJS, Firebase",
      url: "/framey",
    }
  }

  constructor(private router: Router) { }

  ngOnInit(): void {  
  }

  goNext(){
    document.getElementsByTagName("html")[0].style.scrollBehavior = "auto";
    this.router.navigate([this.projects[this.nextProject].url]);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });  
    document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth";

  }

  goPrevious(){
    document.getElementsByTagName("html")[0].style.scrollBehavior = "auto";
    this.router.navigate([this.projects[this.previousProject].url]);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });  
    document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth";
  }

}
