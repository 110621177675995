<custom-cursor id="cursor-container"></custom-cursor>
<bg-shapes></bg-shapes>

<div class="container sticky" style="top: 20px; z-index: 2;">
  <div class="d-block" style="position: relative ">
    <div class="nav-bar d-flex justify-content-end align-items-center sticky">
      <div>

        <div>
          <div class="back-button animate-on-hover d-flex justify-content-between align-items-center float-right"
            (click)="goHome()">
            <icon-long-arrow-back></icon-long-arrow-back>
            <span class="ml-3">Home</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container pt-5 position-relative">
  <div class="title">My e-mail signature</div>

  <div
    class="mt-5 mb-5 d-flex align-items-center justify-content-around flex-wrap gap-2"
  >
    <div>
      <table>
        <tr>
          <td>
            <img
              style="padding-right: 12px"
              height="160px"
              width="auto"
              src="./../../../assets/signature/photo.png"
            />
          </td>
          <td>
            <div style="color: #00a3c7; font-weight: 700; font-size: 16px">
              Eduard Lache
            </div>

            <div style="color: #474747; font-weight: 500; font-size: 14px">
              Senior Typescript Developer • Contractor • Freelancer
            </div>

            <div
              style="
                margin-top: 12px;
                font-size: 14px;
                color: #474747 !important;
              "
            >
              <table>
                <tr>
                  <td>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="tel:+40787827444"
                        style="
                          text-decoration: none;
                          color: #474747 !important;
                          margin-top: 6px;
                          cursor: pointer;
                        "
                      >
                        <img
                          width="20px"
                          src="./../../../assets/signature/phone.png"
                          alt="Phone number"
                        />
                      </a>
                    </div>
                  </td>
                  <td style="padding-left: 4px">
                    <div>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="tel:+40787827444"
                        style="
                          text-decoration: none;
                          color: #474747 !important;
                          margin-top: 6px;
                          cursor: pointer;
                        "
                      >
                        (+40) 787 827 444
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="mailto:hi@eduard.digital"
                        style="
                          text-decoration: none;
                          color: #474747 !important;
                          margin-top: 6px;
                          cursor: pointer;
                        "
                      >
                        <img
                          width="20px"
                          src="./../../../assets/signature/mail.png"
                          alt="Email address"
                        />
                      </a>
                    </div>
                  </td>
                  <td style="padding-left: 4px">
                    <div style="
                          text-decoration: none;
                          color: #474747 !important;
                        ">
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="mailto:hi@eduard.digital"
                        style="
                          text-decoration: none;
                          color: #474747 !important;
                          margin-top: 6px;
                          cursor: pointer;
                        "
                      >
                      <div style="
                          text-decoration: none;
                          color: #474747 !important;
                        ">
                        hi@eduard.digital
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="https://eduard.digital"
                        style="
                          text-decoration: none;
                          color: #474747 !important;
                          margin-top: 6px;
                          cursor: pointer;
                        "
                      >
                        <img
                          width="20px"
                          src="./../../../assets/signature/website.png"
                          alt="Website"
                        />
                      </a>
                    </div>
                  </td>
                  <td style="padding-left: 4px">
                    <div>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="https://eduard.digital"
                        style="
                          text-decoration: none;
                          color: #474747 !important;
                          margin-top: 6px;
                          cursor: pointer;
                        "
                      >
                        eduard.digital
                      </a>
                    </div>
                  </td>
                </tr>
              </table>

                <table style="margin-top: 12px">
                  <tr>
                    <td>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="https://www.linkedin.com/in/eduard-lache-6ba588b1/"
                        style="text-decoration: none; margin-right: 8px"
                      >
                        <img
                          width="20px"
                          src="./../../../assets/signature/linkedin.png"
                          alt="Linkedin"
                        />
                      </a>
                    </td>

                    <td>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="https://www.github.com/Edduard"
                        style="text-decoration: none; margin-right: 8px"
                      >
                        <img
                          width="20px"
                          src="./../../../assets/signature/github.png"
                          alt="Github"
                        />
                      </a>
                    </td>

                    <td>
                      <a
                        target="_blank"
                        rel="noopener,noreferrer"
                        href="https://join.skype.com/invite/LTbfC2Z7by8h"
                        style="text-decoration: none; margin-right: 8px"
                      >
                        <img
                          width="20px"
                          src="./../../../assets/signature/skype.png"
                          alt="Skype"
                        />
                      </a>
                    </td>
                  </tr>
                </table>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<app-footer id="contact"></app-footer>
