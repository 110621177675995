import {
  Component,
  OnInit
} from '@angular/core';
import {
  AngularFireDatabase
} from '@angular/fire/database';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pad-jam',
  templateUrl: './pad-jam.component.html',
  styleUrls: ['./pad-jam.component.scss']
})
export class PadJamComponent implements OnInit {

  constructor(private rtdb: AngularFireDatabase) {}

  isController = false;
  showController = false;
  isScreen = false;
  showScreen = false;
  isRealController = false;
  showRealController = false;

  roomCode = "";

  roomObject = {
    playerControls: [{
      x: 0,
      y: 0,
    }],
    gameState: {
      playerPositions: [{
        x: 0,
        y: 0
      }]
    },


  }

  controls;
  controllerID = 0;
  roomCodeModel = new FormGroup({
    roomCode: new FormControl('', Validators.required)
  });

  ngOnInit(): void {
    
  }

  createRoom(code) {
    return new Promise((resolve, reject) => {
      console.log(code);
  
      this.rtdb.object('rooms/'+code).valueChanges().pipe(take(1)).subscribe(room => {
        console.log(room);

        if(room && room["playerControls"].length){
          console.log("old room")
          resolve("A");
          return this.rtdb.object('rooms/'+code+"/playerControls/1").update({x: 0, y: 0});
        }
        else{
          console.log("new room")
          resolve("B");
          return this.rtdb.object('rooms/'+code).update(this.roomObject);
        }
  
     })
     
    })
  }

  joinRoom(): void {

  }

  becomeController(controllerID): void {
    this.isController = true;
    this.controllerID = controllerID;
  }

  becomeScreen(): void {
    this.isScreen = true;
  }

  becomeRealController(): void{
    this.isRealController = true;
  }

  onFormSubmit(): void {

    if (this.roomCodeModel.valid) {
      this.roomCode = this.roomCodeModel.value.roomCode

      console.log(this.roomCode);
      this.createRoom(this.roomCode).then((resp)=>{
        if(this.isController) this.showController = true;
        if(this.isScreen) this.showScreen = true;
        if(this.isRealController) this.showRealController = true;
      });

    } else {
     console.log("code req");
    }
  }

}
