import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFirestore } from '@angular/fire/firestore';

import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';

import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
export interface Item {
  name: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {}
  messageModel = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,13}$'),
    ]),
    message: new FormControl('', Validators.required),
  });

  actionButtonLabel = 'Send message';
  currentYear = new Date().getFullYear();

  async ngOnInit(): Promise<void> {}

  onFormSubmit(formData: any, formDirective: FormGroupDirective): void {
    this.actionButtonLabel = '...';

    if (this.messageModel.valid) {
      this.analytics.logEvent('action', 'contact', 'message', 'sent', 1);

      let formValues = this.messageModel.value;
      formValues.timestamp = new Date();

      this.afs
        .collection('formMessages')
        .add(formValues)
        .then((res) => {
          // console.log(res);

          // this.messageModel.reset();
          // Object.keys(this.messageModel.controls).forEach(key => {
          //   this.messageModel.controls[key].setErrors(null)
          // });
          formDirective.resetForm();
          this.messageModel.reset();
          this.actionButtonLabel = 'Thanks 😊';
        })
        .catch((err) => {
          // console.log(err);
          this.actionButtonLabel = 'Sorry, there was an error 😢';
          setTimeout(
            function () {
              this.actionButtonLabel = "Let's try again 😎";
            }.bind(this),
            3000
          );
        });
    } else {
      this.actionButtonLabel = 'Oops 🤭 need more info';
      setTimeout(
        function () {
          this.actionButtonLabel = "Let's try again 😎";
        }.bind(this),
        3000
      );
    }
  }
}
