import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { IzibacComponent } from './projects/izibac/izibac.component';
import { DragNDropComponent } from './drag-n-drop/drag-n-drop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TimelineProjectCardsComponent } from './components/timeline-project-cards/timeline-project-cards.component';
import { PetecoComponent } from './projects/peteco/peteco.component';
import { ScoalaDeBusinessComponent } from './projects/scoala-de-business/scoala-de-business.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { ReactiveFormsModule } from '@angular/forms';

import { environment } from 'src/environments/environment';

import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import { BgShapesComponent } from './components/bg-shapes/bg-shapes.component';
import { ProjectsNavigatorComponent } from './components/projects-navigator/projects-navigator.component';
import { AnimatedIconsModule } from './animated-icons/animated-icons.module';
import { PadJamComponent } from './side-quests/pad-jam/pad-jam.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { CustomCursorComponent } from './components/custom-cursor/custom-cursor.component';
import { CVComponent } from './side-quests/cv/cv.component';
import { MailSignatureComponent } from './side-quests/mail-signature/mail-signature.component';
import { ProjectRequestsComponent } from './side-quests/project-requests/project-requests.component';
import { ActionCardComponent } from './components/action-card/action-card.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    ProjectCardComponent,
    IzibacComponent,
    DragNDropComponent,
    TimelineProjectCardsComponent,
    PetecoComponent,
    ScoalaDeBusinessComponent,
    BgShapesComponent,
    ProjectsNavigatorComponent,
    PadJamComponent,
    RatingStarsComponent,
    CustomCursorComponent,
    CVComponent,
    ProjectRequestsComponent,
    MailSignatureComponent,
    ActionCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    DragDropModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    AngularFireAnalyticsModule,
    AnimatedIconsModule,
  ],
  providers: [
    ScreenTrackingService,
    {
      provide: 'G-JDDXDLGEBF',
      useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: false,
        anonymize_ip: false,
        app_name: true,
        app_version: true,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
