import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ExternalLinkComponent } from "./external-link/external-link.component";
import { InternalFullscreenComponent } from "./internal-fullscreen/internal-fullscreen.component";
import { LongArrowBackComponent } from "./long-arrow-back/long-arrow-back.component";
import { BurgerMenuComponent } from "./burger-menu/burger-menu.component";
import { SlideOpenComponent } from "./slide-open/slide-open.component";
import { SlideCloseComponent } from "./slide-close/slide-close.component";
import { WriteEnvelopeComponent } from './write-envelope/write-envelope.component';
import { IndicatorLeftComponent } from './indicator-left/indicator-left.component';
import { IndicatorRightComponent } from './indicator-right/indicator-right.component';
import { ThemeTogglerComponent } from './theme-toggler/theme-toggler.component';
import { IndicatorBottomComponent } from "./indicator-bottom/indicator-bottom.component";
import { IndicatorUpComponent } from "./indicator-up/indicator-up.component";

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, RouterModule, FormsModule],
  declarations: [
    ExternalLinkComponent,
    InternalFullscreenComponent,
    LongArrowBackComponent,
    BurgerMenuComponent,
    SlideOpenComponent,
    SlideCloseComponent,
    WriteEnvelopeComponent,
    IndicatorLeftComponent,
    IndicatorRightComponent,
    IndicatorBottomComponent,
    IndicatorUpComponent,
    ThemeTogglerComponent,
  ],
  exports: [
    ExternalLinkComponent,
    InternalFullscreenComponent,
    LongArrowBackComponent,
    BurgerMenuComponent,
    SlideOpenComponent,
    SlideCloseComponent,
    WriteEnvelopeComponent,
    IndicatorLeftComponent,
    IndicatorRightComponent,
    IndicatorBottomComponent,
    IndicatorUpComponent,
    ThemeTogglerComponent,
  ]
})
export class AnimatedIconsModule {}
