<!-- <div class="nav-bar" routerLink="/">
    <div class="back-button align-items-center d-flex ml-5 p-3">
        <img loading="lazy" src="./assets/icons/back-white.svg" class="mr-2"/> <div style="color: white">Back to all projects</div>
    </div>
</div> -->
<!-- <iframe style="height: 100vh; width: 100vw; border: none; margin-bottom: -5px;" width="100%" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmNAtBkfIMTn5J60u3QbJL9%2Ftires%3Fnode-id%3D523%253A512%26scaling%3Dscale-down-width%26page-id%3D145%253A331%26starting-point-node-id%3D523%253A512%26hide-ui%3D1" allowfullscreen></iframe> -->


<div class="green-theme">
  <div>
    <bg-shapes></bg-shapes>
    <custom-cursor id="cursor-container"></custom-cursor>

    <div class="container sticky" style="top: 20px; z-index: 2;">
      <div class="d-block" style="position: relative ">
        <div class="nav-bar d-flex justify-content-end align-items-center sticky">
          <div>

            <div>
              <div class="back-button animate-on-hover d-flex justify-content-between align-items-center float-right"
                (click)="goHome()">
                <icon-long-arrow-back></icon-long-arrow-back>
                <span class="ml-3">Home</span>
              </div>
            </div>

            <div class="float-right">
              <div id="show-prototype-button"
                class="d-flex d-lg-none back-button slide-icon animate-on-hover mt-3 justify-content-between align-items-center float-right"
                (click)="showPrototype()" (mouseover)="preparePrototypeTransition()">
                <span>Show</span>
                <icon-slide-open class="ml-2"></icon-slide-open>
              </div>

              <div id="hide-prototype-button"
                class="d-none d-lg-flex back-button slide-icon animate-on-hover mt-3 justify-content-between align-items-center float-right"
                (click)="hidePrototype()">
                <span>Hide&nbsp;</span>
                <icon-slide-close class="ml-2"></icon-slide-close>
              </div>
              <div
                class="d-none d-lg-flex back-button fullscreen-icon animate-on-hover mt-3 mr-2 justify-content-between align-items-center float-right"
                (click)="openPrototype()" (mouseover)="preparePrototypeTransition()">
                <icon-internal-fullscreen></icon-internal-fullscreen>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-6 d-flex">
      <div class="title">Peteco</div>
    </div>

    <div class="container d-flex flex-wrap" style="z-index: 1;">
      <div tabindex=-1 (click)="openPrototype()" (mouseover)="preparePrototypeTransition()" class="mt-4 mr-4">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Figma prototype
          <icon-internal-fullscreen class="ml-2"></icon-internal-fullscreen>
        </button>
      </div>
      <a tabindex=-1 class="mt-4 mr-4" href="https://www.peteco.ro/" target="_blank" rel="noopener"
        aria-label="Peteco website">
        <button mat-flat-button color color="primary" class="action-button animate-on-hover" tabindex=0>Website
          <icon-external-link class="ml-2"></icon-external-link></button>
      </a>
    </div>


    <div class="container d-block d-lg-flex">
      <div class="">
        <div id="projects" class="projects">
        </div>

        <div class="glass-card mt-5" tabindex=0>
          <div class="subtitle card-title color-primary">
            About project
          </div>
          <div class="card-content">
            <div class="d-flex flex-column h-100 justify-content-between">
              <p class="article-text">
                Scope of the project was creating a cross-platform mobile app for the first social marketplace in the
                world designed for animal lovers.
              </p>
              <br>
              <div class="article-text">
                <b>My responsibilities:</b>
                <li>
                  UX - wireframes
                </li>
                <li>
                  UI - graphic design
                </li>
              </div>
              <br>
              <div class="article-text">
                <b>Some skills I learned:</b>
                <li>
                  Figma
                </li>
                <li>
                  Product design
                </li>
                <li>
                  Research
                </li>
              </div>
              <br>
            </div>
          </div>
        </div>
        <div class="glass-card mt-5" tabindex=0>
          <div class="subtitle card-title color-primary">
            Context
          </div>
          <div class="card-content">
            <div class="d-flex flex-column h-100 justify-content-between">
              <p class="article-text">
                Peteco was one of Effio’s outsourcing projects. Our role was to take the existing web platform and
                create a mobile app designed specifically for pet lovers.
              </p>
              <br>
              <div class="article-text">
                We had the backend and branding already set-up.
              </div>
              <br>
              <div class="article-text">
                <b>Our team:</b>
                <li>
                  Me - UI & UX
                </li>
                <li>
                  Laurențiu Pantelimon - frontend developer
                </li>
              </div>
              <br>
              <div class="article-text">
                <b>Period:</b>
                <li>
                  2020 - 2021
                </li>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 col-xl-3 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco login" title="Peteco login" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/login.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco index" title="Peteco index" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/index.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco search" title="Peteco search" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/search.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-md-6 col-xl-3 mt-0 mt-md-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco search 2" title="Peteco search 2" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/search-2.jpg" tabindex=0 />
            </div>
          </div>
        </div>

        <div class="glass-card mt-0" tabindex=0>
          <div class="subtitle card-title color-primary">
            The process: UX
          </div>
          <div class="card-content">
            <div class="d-flex flex-column h-100 justify-content-between">
              <p class="article-text">
                I started out by researching the functionalities from the old web platform and other similar platforms
                and apps.
              </p>
              <p class="article-text">
                Defined the user persona and matched the functionalities with the persona.
              </p>
              <p class="article-text">
                Mapped out the information architecture by grouping functionalities to the appropriate section in the
                app.
              </p>
              <p class="article-text">
                Agreed with the client upon the user stories the app will be featuring.
              </p>
              <p class="article-text">
                Mapped the user stories in a dynamic, clickable, wireframe.
              </p>
              <p class="article-text">
                Validated the wireframes with the customer.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-xl-6 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco cart" title="Peteco cart" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/cart.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-12 col-xl-6 mt-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco add review" title="Peteco add review" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/add-review.jpg" tabindex=0 />
            </div>
          </div>
        </div>

        <div class="glass-card mt-0" tabindex=0>
          <div class="subtitle card-title color-primary">
            The process: UI
          </div>
          <div class="card-content">
            <div class="d-flex flex-column h-100 justify-content-between">
              <p class="article-text">
                Created the grid system.
              </p>
              <p class="article-text">
                Grouped similar elements into figma components.
              </p>
              <p class="article-text">
                Decided on the most appropriate typography and graphic design style.
              </p>
              <p class="article-text">
                Designed every screen and component and validated with customers.
              </p>
              <p class="article-text">
                Mapped every functionality to a screen/component.
              </p>
              <p class="article-text">
                Linked everything together in a figma prototype so that everyone could play with it.
              </p>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-xl-6 mt-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco order" title="Peteco order" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/order.jpg" tabindex=0 />
            </div>
          </div>
          <div class="col-xl-6 mt-0 mt-xl-5 mb-5">
            <div class="col-12 p-0">
              <img loading="lazy" alt="Peteco recover password" title="Peteco recover password" class="w-100 border-radius-20"
                src="./../../../assets/images/peteco/full-res/recover-password.jpg" tabindex=0 />
            </div>
          </div>
        </div>

        <div class="p-0">
          <div class="glass-card col-12" tabindex=0>
            <div class="subtitle card-title color-primary">
              Technologies
            </div>
            <div class="card-content article-text">
              <div class="d-flex h-100 justify-content-around flex-wrap">
                <div class="tech-item">
                  <img loading="lazy" alt="Figma" title="Figma" class="p-4" src="./../../../assets/logos/figma-simple.png" />
                  <div class="text-center w-100 mt-4">
                    <b>Figma</b>
                  </div>
                </div>
                <div class="tech-item">
                  <img loading="lazy" alt="React native" title="React native" class="p-3" src="./../../../assets/logos/react-native.png" />
                  <div class="text-center w-100 mt-4">
                    <b>React native</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div id="prototype-backdrop" (click)="closePrototype()">
      </div>
      <div id="prototype-container" class="prototype-container d-lg-block">
        <div class="mobile-frame ml-5 mt-5">
          <iframe id="prototype-iframe" style="height: 100%; width: 100%; border: none; margin-bottom: -5px;"
            width="100%" title="Figma prototype"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FsJQkqJ4P8PgDmKAJfOAkCi%2FEcommerce%3Fnode-id%3D1125%253A5713%26scaling%3Dscale-down-width%26page-id%3D117%253A0%26starting-point-node-id%3D1125%253A5702%26hide-ui%3D1"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <rating-stars [project]="'peteco'"></rating-stars>

  <projects-navigator [previousProject]="'izibac'" [nextProject]="'scoala-de-business'"></projects-navigator>

  <a href="peteco#contact">
    <div id="contact-bubble"
      class="contact-bubble visible d-flex align-items-center justify-content-center animate-on-hover">
      <icon-write-envelope></icon-write-envelope>
    </div>
  </a>

  <app-footer id="contact"></app-footer>

</div>
