import { AfterViewInit, Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss']
})

/**
 * @title Drag&Drop test
 */
export class DragNDropComponent implements AfterViewInit {
  constructor() { }
  ngAfterViewInit(): void {
  }
  customers = ["[VIP] Amman Holloway","[VIP] Taha Barnett","[VIP] Kerri Mcloughlin","Laaibah Meyers","Mandeep Turnbull","Julien Guevara","Radhika Blundell","Leigh Coffey","Rees Stanton","Conrad Edwards"];
  table1 = [];
  table2 = [];
  VIPtables = [];

  drop(event: CdkDragDrop<number[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  /** Predicate function that only allows even numbers to be dropped into a list. */
  normalTablePredicat(item: any) {
    if(item.data.split(" ")[0] == "[VIP]") 
    {
      // alert("Doar un [VIP] poate sta la aceasta masa");
      return false;
    }
    else{
      return true;
    }
  }

  /** Predicate function that only allows even numbers to be dropped into a list. */
  VIPTablePredicat(item: any) {
    
    if(item.data.split(" ")[0] == "[VIP]") return true
    else{
      // alert("Un [VIP] nu poate sta la aceasta masa");
      return false;
    }
    
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  alwaysTruePredicat(item: any) {
    return true;
  }
}

