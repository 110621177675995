import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() projectIndex: number;
  @Input() contentWidth: number = 4;
  @Input() projectTitle: string;
  @Input() projectImages: Array<String>;

  isScreenSmall$: any;
  isDragging = false;
  userClickedPhoto = false;
  shouldOpenGallery = true;
  isPictureOpened = false;
  activeFullscreenImage;
  activeFullscreenImageClone;
  selectedImageOriginalParent;
  bindedFullscreenRemovalScrollListner;
  backdrop;
  carousel;
  scrollableArea;
  previousImageClone;
  nextImageClone;
  currentCarouselIndex = 0;
  carouselEventListeners = {};
  shouldScroll = true;
  showLeftScrollControl = false;
  showRightScrollControl = true;
  removableBackdropClickHandler;

  nftPlatformContent = {
    iframeURL: 'https://nft-galaxy-marketplace.web.app/',
    // 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3f0sQhGxrHw147DXR6Rari/Public-%5BART-Collection%5D?type=design&node-id=1-858&t=jcwhQMaNH2sOhtob-0&scaling=scale-down-width&page-id=0%3A1&starting-point-node-id=1%3A858&hide-ui=1',
    // 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fx9oBQ3rUICskCPIQPSYIwC%2FNFT-Marketplace%3Ftype%3Ddesign%26node-id%3D147-2675%26t%3DUvko6HnrVdqb7Wur-1%26scaling%3Dscale-down-width%26page-id%3D43%253A7%26starting-point-node-id%3D147%253A2675%26show-proto-sidebar%3D1%26mode%3Ddesign&show-proto-sidebar=1&hide-ui=1',
    iframeParameters: {
      width: { amount: 80, units: 'vw' },
      height: { amount: 90, units: 'vh' },
    },
  };

  constructor(
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.applyScollAnimations();
  }

  applyScollAnimations() {
    let startX;
    let scrollLeft;
    // let scrollableArea;

    this.scrollableArea = document.getElementById(
      'animated-horizontal-scroll-' + this.projectIndex
    );

    this.scrollableArea.addEventListener(
      'mousedown',
      (e) => {
        this.userClickedPhoto = true;
        this.shouldOpenGallery = true;

        this.scrollableArea.classList.add('active');
        this.scrollableArea.style.userSelect = 'none';
        startX = e.pageX - this.scrollableArea.offsetLeft;
        scrollLeft = this.scrollableArea.scrollLeft;
      },
      { passive: true }
    );

    this.scrollableArea.addEventListener(
      'mouseleave',
      (event) => {
        console.log('mouseleave', event);
        this.userClickedPhoto = false;
        this.isDragging = false;
        this.shouldOpenGallery = false;
        this.scrollableArea.classList.remove('active');
      },
      { passive: true }
    );

    this.scrollableArea.addEventListener(
      'mouseup',
      (e) => {
        this.userClickedPhoto = false;
        this.isDragging = false;

        this.scrollableArea.classList.remove('active');
      },
      { passive: true }
    );

    this.scrollableArea.addEventListener('mousemove', (e) => {
      this.isDragging = true;
      if (!this.userClickedPhoto) return;
      e.preventDefault();
      const x = e.pageX - this.scrollableArea.offsetLeft;
      const walk = (x - startX) * 1.8; //scroll-fast
      this.scrollableArea.scrollLeft = scrollLeft - walk;

      // Only open the picture if the user scrolled a little bit ( < |50px| )
      if (walk > -50 && walk < 50) {
        this.shouldOpenGallery = true;
      } else {
        this.shouldOpenGallery = false;
      }
    });

    // Apply opacity for content
    // ToDo: If we start as a mobile screen, don't do this as it's already a different layout
    let cardContent: any = document.getElementById(
      'project-card-content-' + this.projectIndex
    );
    if (cardContent) {
      this.scrollableArea.addEventListener(
        'scroll',
        function (e) {
          if (this.scrollableArea.scrollLeft < cardContent.offsetWidth) {
            cardContent.style.opacity =
              1 - this.scrollableArea.scrollLeft / cardContent.offsetWidth;
            this.showLeftScrollControl = false;
          } else {
            // console.log(this.scrollableArea.scrollLeft, "/", this.scrollableArea.scrollWidth - this.scrollableArea.clientWidth);
            if (
              this.scrollableArea.scrollLeft >=
              this.scrollableArea.scrollWidth - this.scrollableArea.clientWidth
            ) {
              this.showRightScrollControl = false;
            } else {
              this.showRightScrollControl = true;
            }
            this.showLeftScrollControl = true;
            cardContent.style.opacity = 0;
          }
        }.bind(this),
        { passive: true }
      );
    }
  }

  smoothHorizontalScroll(element, amount) {
    let oldPosition = element.scrollLeft;
    element.scrollBy({
      left: Math.ceil(amount),
      behavior: 'auto',
    });

    return new Promise<void>((resolve, reject) => {
      const failed = setTimeout(() => {
        reject();
      }, 2000);
      const scrollHandler = () => {
        if (amount < 0) {
          //   if ((Math.ceil(element.scrollLeft) >= (oldPosition + Math.ceil(amount)) * 0.9) && (Math.ceil(element.scrollLeft) <= (oldPosition + Math.ceil(amount)) * 1.1)) {
          //   }

          if (element.scrollLeft <= Math.ceil(amount)) {
            window.removeEventListener('scroll', scrollHandler);
            clearTimeout(failed);
            resolve();
          }
        } else {
          if (element.scrollLeft >= oldPosition + Math.ceil(amount)) {
            window.removeEventListener('scroll', scrollHandler);
            clearTimeout(failed);
            resolve();
          }
        }
      };
      if (element.scrollLeft >= oldPosition + Math.ceil(amount)) {
        clearTimeout(failed);
        resolve();
      }
    });
  }

  stopScroll(): void {
    this.shouldScroll = false;
  }

  startScroll(event, incrementSeed): void {
    event.preventDefault();
    event.stopPropagation();
    this.shouldScroll = true;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    let increment = incrementSeed;
    let index = 1;

    function easingFunction(x) {
      let y;
      // Our index might go up to 1000 so we want to limit that
      // Also, for the functions to work, x must be between [0,1]
      if (x > 1000) x = 1000;
      x = x / 1000;

      // easeOutSine
      // y = 1 - Math.sin((x * Math.PI) / 2);

      // easeInSine
      // y = 1 - Math.cos((x * Math.PI) / 2);

      // easeOutQuint
      // y = 1 - Math.pow(1 - x, 5);

      // easeOutCirc
      y = Math.sqrt(1 - Math.pow(x - 1, 2));

      // easeOutCubic
      // y = 1 - Math.pow(1 - x, 3);

      // Other working functions
      // y = (x === 0 ? 0 : Math.pow(2, 10 * x - 10))
      // y = (2 - Math.pow(1 - x, 1.675))
      return 1 + y;
    }
    let cardContent: any = document.getElementById(
      'project-card-content-' + this.projectIndex
    );

    (async () => {
      while (this.shouldScroll) {
        index++;
        if (
          this.scrollableArea.scrollLeft + increment >=
          this.scrollableArea.scrollWidth
        ) {
          increment = incrementSeed;
        }
        try {
          await this.smoothHorizontalScroll(this.scrollableArea, increment);

          if (incrementSeed < 0) {
            // Scrolls towards start
            if (this.scrollableArea.scrollLeft < cardContent.offsetWidth) {
              // Smooth out when comes back and remove the button
              increment = -10;
              this.showLeftScrollControl = false;

              // Ease scrolling
              increment =
                increment *
                (this.scrollableArea.scrollLeft / cardContent.offsetWidth);
              if (increment > -5) increment = -3;
              if (this.scrollableArea.scrollLeft == 0) {
                increment = 0;
                this.stopScroll();
              }
            } else if (increment < -30) {
              // Top the speed at 30 when moving to the start
              increment = -30;
            } else {
              increment = increment * easingFunction(index);
            }
          } else {
            // Scrolls towards end
            if (this.scrollableArea.scrollLeft > cardContent.offsetWidth) {
              this.showLeftScrollControl = true;
            }
            if (increment > 4) {
              // Top the speed at 4 when moving left
              increment = 4;
            } else {
              increment = increment * easingFunction(index);
            }
          }
        } catch (err) {
          console.log('Err on scroll', err);
          this.stopScroll();
        }
        await delay(1);
      }
    })();
  }

  openSafelyInNewTab(url): void {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  clickOnCard(): void {
    if (!this.isDragging) {
      if (this.projectIndex >= 0) {
        // When changing page scroll to top immediately
        document.getElementsByTagName('html')[0].style.scrollBehavior = 'auto';

        if (this.projectIndex == 0) {
          this.router.navigate(['/izibac']);
        }
        if (this.projectIndex == 1) {
          this.router.navigate(['/peteco']);
        }
        if (this.projectIndex == 2) {
          this.router.navigate(['/scoala-de-business']);
        }
        if (this.projectIndex == 3) {
          this.openSafelyInNewTab('https://findyourwhy.ro/');
        }

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
        document.getElementsByTagName('html')[0].style.scrollBehavior =
          'smooth';
      }
    }
  }

  clickOnPicture(event, index): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.shouldOpenGallery) {
      // this.analytics.eventEmitter("add_to_cart", "shop", "cart", "click", 10);
      this.analytics.logEvent(
        'view_item',
        'projects',
        'images',
        event.target.src,
        index
      );

      // console.log("Clicked on ", event, " index ", index, " from project ", this.projectIndex);
      this.currentCarouselIndex = index;

      this.activeFullscreenImage = event.target;
      this.activeFullscreenImageClone =
        this.activeFullscreenImage.cloneNode(true);
      this.selectedImageOriginalParent =
        this.activeFullscreenImage.parentElement;

      let fullscreenImagePosition =
        this.activeFullscreenImage.getBoundingClientRect();

      if (!this.isPictureOpened) {
        this.selectedImageOriginalParent.appendChild(
          this.activeFullscreenImageClone
        );

        // console.log("this.activeFullscreenImage.src", this.activeFullscreenImage.src);
        // console.log("this.activeFullscreenImage.src", this.activeFullscreenImage.src.split("/"));

        // this.activeFullscreenImage.style.position = "fixed";
        this.activeFullscreenImage.style.width =
          fullscreenImagePosition.width + 'px';
        this.activeFullscreenImage.style.height =
          fullscreenImagePosition.height + 'px';
        this.activeFullscreenImage.style.top =
          fullscreenImagePosition.top + 'px';
        this.activeFullscreenImage.style.left =
          fullscreenImagePosition.left + 'px';
        this.activeFullscreenImage.style.opacity = '1';

        setTimeout(
          function () {
            // Replace the current images with higher quality from full-res folder
            // But only do it after the transition has happened ( 0.4s is the transition duration for the animation)
            this.activeFullscreenImage.src =
              this.activeFullscreenImage.src.replace(
                '/thumbnails',
                '/full-res'
              );
          }.bind(this),
          400
        );

        // this.activeFullscreenImage.style.left = this.scrollableArea.scrollWidth + index.target.parentElement.clientWidth/2 + "px";

        // this.activeFullscreenImageClone.style = "opacity: 1";
        // this.backdrop = document.createElement("div");

        // console.log("1", this.scrollableArea.scrollLeft);
        // let scrollPerserver = this.scrollableArea.scrollLeft/this.scrollableArea.scrollWidth;
        // console.log("2", scrollPerserver);

        this.backdrop = document.getElementById(
          'general-backdrop-' + this.projectIndex
        );
        this.carousel = document.createElement('carousel-container');

        let carouselControlNext = document.getElementById(
          'carouselControl-next-' + this.projectIndex
        );
        let carouselControlPrevious = document.getElementById(
          'carouselControl-prev-' + this.projectIndex
        );

        carouselControlNext.classList.remove('hidden');
        carouselControlPrevious.classList.remove('hidden');

        // Add the picture that should be before
        this.previousImageClone = this.activeFullscreenImage.cloneNode(true);
        this.previousImageClone.src = '';
        if (this.currentCarouselIndex > 0) {
          this.previousImageClone.src =
            this.projectImages[this.currentCarouselIndex - 1];
        } else {
          // Hide previous button
          carouselControlPrevious.classList.add('hidden');
        }
        this.previousImageClone.classList.add('previous-image');
        this.carousel.appendChild(this.previousImageClone);

        // Add the picture that should be after
        this.nextImageClone = this.activeFullscreenImage.cloneNode(true);
        this.nextImageClone.src = '';
        if (this.currentCarouselIndex < this.projectImages.length - 1) {
          this.nextImageClone.src =
            this.projectImages[this.currentCarouselIndex + 1];
        } else {
          // Hide next button
          carouselControlNext.classList.add('hidden');
        }
        this.nextImageClone.classList.add('next-image');
        this.carousel.appendChild(this.nextImageClone);

        // Add the pictures to the carousel
        this.carousel.appendChild(this.activeFullscreenImage);

        // Add the carousel to the backdrop
        this.backdrop.appendChild(this.carousel);

        // Add the backdrop to the body
        document.getElementById('body-container').append(this.backdrop);

        this.addCarouselEventListeners();

        this.isPictureOpened = true;

        setTimeout(
          function () {
            this.backdrop.classList.add('picture-backdrop');
            this.activeFullscreenImage.classList.add('fullscreen-card-picture');
            this.activeFullscreenImage.classList.add('active-image');

            this.previousImageClone.classList.add('fullscreen-card-picture');
            this.nextImageClone.classList.add('fullscreen-card-picture');

            this.backdrop.classList.add('active');

            // this.activeFullscreenImageClone.classList.add('fullscreen-card-picture-clone');
          }.bind(this, this.previousImageClone, this.nextImageClone),
          10
        );
      } else {
        this.isPictureOpened = false;
        this.removeFullScreen();
      }
    }
  }

  removeFullScreen(): void {
    // console.log("removeFullScreen", this.isPictureOpened);
    if (this.isPictureOpened) {
      this.isPictureOpened = false;
      // this.selectedImageOriginalParent.appendChild(this.activeFullscreenImage);

      // this.activeFullscreenImage.style = `position: relative`;

      // if (this.activeFullscreenImage.parentElement) {
      // this.activeFullscreenImage.parentElement.classList.add('removed-fullscreen-card-picture');
      // }

      // this.activeFullscreenImage.parentElement.classList.remove('removed-fullscreen-card-picture');
      this.activeFullscreenImage.classList.remove('fullscreen-card-picture');
      this.activeFullscreenImage.classList.remove('active-image');
      this.backdrop.classList.remove('active');

      // this.activeFullscreenImageClone.parentElement.classList.remove('removed-fullscreen-card-picture');

      let fullscreenImageClonePosition =
        this.scrollableArea.children[
          this.currentCarouselIndex + 1
        ].getBoundingClientRect();

      this.activeFullscreenImage.style.position = 'fixed';
      this.activeFullscreenImage.style.width = 'auto';
      this.activeFullscreenImage.style.height =
        fullscreenImageClonePosition.height + 'px';
      this.activeFullscreenImage.style.top =
        fullscreenImageClonePosition.top + 'px';
      this.activeFullscreenImage.style.left =
        fullscreenImageClonePosition.left + 'px';
      this.activeFullscreenImage.style.transform = 'translate: (-50%, 0%)';

      setTimeout(
        function () {
          this.activeFullscreenImage.style = '';
          // this.activeFullscreenImageClone.remove();

          this.carousel.remove();
          // If we accidentally left any carousels hanging
          let unremovedCarousels =
            document.getElementsByTagName('carousel-container');
          if (unremovedCarousels.length > 0) {
            for (
              let carouselIndex = 0;
              carouselIndex < unremovedCarousels.length;
              carouselIndex++
            ) {
              // console.log(carouselIndex, " of ", unremovedCarousels.length)
              // console.log(unremovedCarousels[carouselIndex]);
              unremovedCarousels[carouselIndex].remove();
            }
          }
          this.selectedImageOriginalParent.appendChild(
            this.activeFullscreenImageClone
          );

          this.backdrop.classList.remove('picture-backdrop');
          // this.activeFullscreenImageClone.classList.remove('fullscreen-card-picture');
        }.bind(this),
        300
      );
    }
    this.removeCarouselEventListners();
  }

  handleCarouselPrevious(event): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.currentCarouselIndex - 1 >= 0) {
      if (this.currentCarouselIndex == 1) {
        document
          .getElementById('carouselControl-prev-' + this.projectIndex)
          .classList.add('hidden');
      } else {
        document
          .getElementById('carouselControl-next-' + this.projectIndex)
          .classList.remove('hidden');
      }
      let clickedElement =
        this.scrollableArea.children[this.currentCarouselIndex + 1];
      this.currentCarouselIndex--;

      this.scrollableArea.scrollTo({
        top: 0,
        left: this.scrollableArea.scrollLeft - clickedElement.scrollWidth * 0.7,
        behavior: 'smooth',
      });

      this.previousImageClone.classList.remove('previous-image');
      this.previousImageClone.classList.add('active-image');

      // console.log("Should have active <- ", this.activeFullscreenImage.classList);
      this.activeFullscreenImage.classList.remove('active-image');
      this.activeFullscreenImage.classList.add('next-image');

      // console.log("Should have next <- ", this.nextImageClone.classList);
      this.nextImageClone.classList.remove('next-image');
      this.nextImageClone.classList.add('previous-image');
      this.nextImageClone.classList.add('hidden-image');

      let auxImage = this.previousImageClone;
      this.previousImageClone = this.nextImageClone; // ToDo
      this.nextImageClone = this.activeFullscreenImage;
      this.activeFullscreenImage = auxImage;

      if (this.currentCarouselIndex > 0) {
        this.previousImageClone.src =
          this.projectImages[this.currentCarouselIndex - 1];
      } else {
        this.previousImageClone.src = '';
      }
      this.activeFullscreenImage.src =
        this.projectImages[this.currentCarouselIndex];
      this.activeFullscreenImage.src = this.activeFullscreenImage.src.replace(
        '/thumbnails',
        '/full-res'
      );

      this.nextImageClone.src =
        this.projectImages[this.currentCarouselIndex + 1];
      this.nextImageClone.src = this.nextImageClone.src.replace(
        '/thumbnails',
        '/full-res'
      );

      setTimeout(
        function () {
          this.previousImageClone.classList.remove('hidden-image');
        }.bind(this),
        10
      );
    }
  }

  handleCarouselNext(event): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.currentCarouselIndex + 1 < this.projectImages.length) {
      if (this.currentCarouselIndex + 1 == this.projectImages.length - 1) {
        document
          .getElementById('carouselControl-next-' + this.projectIndex)
          .classList.add('hidden');
      } else {
        document
          .getElementById('carouselControl-prev-' + this.projectIndex)
          .classList.remove('hidden');
      }

      let clickedElement =
        this.scrollableArea.children[this.currentCarouselIndex + 1];
      this.currentCarouselIndex++;

      this.scrollableArea.scrollTo({
        top: 0,
        left: this.scrollableArea.scrollLeft + clickedElement.scrollWidth * 0.7,
        behavior: 'smooth',
      });

      // console.log("Should have next -> ", this.nextImageClone.classList);
      this.nextImageClone.classList.remove('next-image');
      this.nextImageClone.classList.add('active-image');

      // console.log("Should have active -> ", this.activeFullscreenImage.classList);
      this.activeFullscreenImage.classList.remove('active-image');
      this.activeFullscreenImage.classList.add('previous-image');

      // console.log("Should have previous -> ", this.previousImageClone.classList);
      this.previousImageClone.classList.remove('previous-image');
      this.previousImageClone.classList.add('next-image'); //ToDO
      this.previousImageClone.classList.add('hidden-image');

      let auxImage = this.previousImageClone;
      this.previousImageClone = this.activeFullscreenImage;
      this.activeFullscreenImage = this.nextImageClone;
      this.nextImageClone = auxImage; // ToDo

      this.previousImageClone.src =
        this.projectImages[this.currentCarouselIndex - 1];
      this.previousImageClone.src = this.previousImageClone.src.replace(
        '/thumbnails',
        '/full-res'
      );

      this.activeFullscreenImage.src =
        this.projectImages[this.currentCarouselIndex];
      this.activeFullscreenImage.src = this.activeFullscreenImage.src.replace(
        '/thumbnails',
        '/full-res'
      );

      if (this.currentCarouselIndex < this.projectImages.length - 1) {
        this.nextImageClone.src =
          this.projectImages[this.currentCarouselIndex + 1];
      } else {
        this.nextImageClone.src = '';
      }

      setTimeout(
        function () {
          this.nextImageClone.classList.remove('hidden-image');
        }.bind(this),
        10
      );
    }
  }

  xDown = null;
  yDown = null;

  handleCarouselTouchStart(event): void {
    function getTouches(event) {
      return (
        event.touches || // browser API
        event.originalEvent.touches
      ); // jQuery
    }

    let firstTouch = getTouches(event)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  handleCarouselTouchEnd(event): void {
    console.log('handleCarouselTouchEnd', event);
    if (!this.xDown || !this.yDown) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  handleCarouselTouchMove(event): void {
    if (!this.xDown || !this.yDown) {
      return;
    }

    let xUp = event.touches[0].clientX;
    let yUp = event.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* right swipe */
        this.handleCarouselNext(event);
      } else {
        /* left swipe */
        this.handleCarouselPrevious(event);
      }
    } else {
      if (yDiff > 0) {
        /* down swipe */
      } else {
        /* up swipe */
      }
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  }

  handleCarouselKeyDown(event): void {
    event = event || window.event;
    if (event.keyCode == '37') {
      // left arrow
      this.handleCarouselPrevious(event);
    } else if (event.keyCode == '39') {
      // right arrow
      this.handleCarouselNext(event);
    }
  }

  handleBackdropClick(event): void {
    this.removeFullScreen();
  }

  addCarouselEventListeners(): void {
    // Previous
    let previous = this.backdrop.querySelector(
      '#carouselControl-prev-' + this.projectIndex
    );
    this.carouselEventListeners['previous'] =
      this.handleCarouselPrevious.bind(this);
    previous.addEventListener(
      'click',
      this.carouselEventListeners['previous'],
      { passive: true }
    );

    // Next
    let next = this.backdrop.querySelector(
      '#carouselControl-next-' + this.projectIndex
    );
    this.carouselEventListeners['next'] = this.handleCarouselNext.bind(this);
    next.addEventListener('click', this.carouselEventListeners['next'], {
      passive: true,
    });

    // Scroll
    this.carouselEventListeners['scroll'] = this.removeFullScreen.bind(this);
    window.addEventListener('scroll', this.carouselEventListeners['scroll'], {
      once: true,
      passive: true,
    });

    // Touch for mobile
    this.carouselEventListeners['touchstart'] =
      this.handleCarouselTouchStart.bind(this);

    this.carousel.addEventListener(
      'touchstart',
      this.carouselEventListeners['touchstart'],
      { passive: true }
    );

    this.carouselEventListeners['touchend'] =
      this.handleCarouselTouchEnd.bind(this);
    this.carousel.addEventListener(
      'touchend',
      this.carouselEventListeners['touchend'],
      { passive: true }
    );

    this.carouselEventListeners['touchmove'] =
      this.handleCarouselTouchMove.bind(this);
    this.carousel.addEventListener(
      'touchmove',
      this.carouselEventListeners['touchmove'],
      { passive: true }
    );

    // Click on backdrop
    this.carouselEventListeners['click'] = this.handleBackdropClick.bind(this);
    this.backdrop.addEventListener(
      'click',
      this.carouselEventListeners['click'],
      { passive: true }
    );

    // Keystrokes
    this.carouselEventListeners['keydown'] =
      this.handleCarouselKeyDown.bind(this);
    document.addEventListener(
      'keydown',
      this.carouselEventListeners['keydown'],
      { passive: true }
    );
  }

  removeCarouselEventListners(): void {
    // Previous
    let previous = this.backdrop.querySelector(
      '#carouselControl-prev-' + this.projectIndex
    );
    previous.removeEventListener(
      'click',
      this.carouselEventListeners['previous']
    );

    // Next
    let next = this.backdrop.querySelector(
      '#carouselControl-next-' + this.projectIndex
    );
    next.removeEventListener('click', this.carouselEventListeners['next']);

    // Touch for mobile
    this.carousel.removeEventListener(
      'touchmove',
      this.carouselEventListeners['touchmove']
    );
    this.carousel.removeEventListener(
      'touchstart',
      this.carouselEventListeners['touchstart']
    );
    this.carousel.removeEventListener(
      'touchend',
      this.carouselEventListeners['touchend']
    );

    // Scroll
    window.removeEventListener('scroll', this.carouselEventListeners['scroll']);

    // Click on backdrop
    this.carouselEventListeners['click'] = this.handleBackdropClick.bind(this);
    this.backdrop.removeEventListener(
      'click',
      this.carouselEventListeners['click']
    );

    // Keystrokes
    document.removeEventListener(
      'keydown',
      this.carouselEventListeners['keydown']
    );
  }

  stopPropagationOnScroll(e): void {
    console.log('e', e);
    // if (!e.target.classList.includes('article-text')) {
    e.stopPropagation();
    // }

    // check if e.srcElement is p.article-text
    // If so, let the click go through

    // let cameFromClickOnContent = false;

    // for (let path of e.path) {
    //   if (path['id'] == 'project-card-content-' + this.projectIndex) {
    //     cameFromClickOnContent = true;
    //   }
    // }

    // if (!cameFromClickOnContent) {
    //   // If inside the scrollable area, don't let the user go to project if it clicked on anything else other than the written content.
    //   e.stopPropagation();
    // }
  }

  openModal(backdrop) {
    backdrop.classList.add('active-backdrop');
  }

  openPrototype(event, iframeURL, iframeParameters): void {
    let backdrop = document.getElementById('global-backdrop');
    backdrop.addEventListener(
      'mousedown',
      (this.removableBackdropClickHandler = function () {
        const globalBackdrop = document.getElementById('global-backdrop');
        globalBackdrop.classList.remove('active-backdrop');
        // This removes all children and only keeps the first one (the close button)
        // @ts-ignore
        globalBackdrop.replaceChildren(globalBackdrop.firstChild);
      }),
      {
        passive: true,
      }
    );

    let iframeContainer = document.createElement('div');
    iframeContainer.classList.add('preview-prototype-iframe-container');
    iframeContainer.style.width =
      iframeParameters.width.amount + iframeParameters.width.units;
    iframeContainer.style.height =
      iframeParameters.height.amount + iframeParameters.height.units;
    iframeContainer.style.minWidth = '300px';
    iframeContainer.style.maxWidth = '95vw';
    iframeContainer.style.maxHeight = '95vh';
    iframeContainer.style.minHeight = `500px`;
    iframeContainer.style.border = '3px solid #343a40';
    iframeContainer.style.backgroundColor = 'black';
    iframeContainer.style.position = 'absolute';
    iframeContainer.style.top = '50%';
    iframeContainer.style.left = '50%';
    iframeContainer.style.transform = 'translate(-50%, -50%)';
    iframeContainer.style.borderRadius = '32px';
    iframeContainer.style.overflow = 'hidden';

    let iframe = document.createElement('iframe');
    iframe.src = iframeURL;
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.style.border = 'none';

    iframeContainer.appendChild(iframe);
    backdrop.appendChild(iframeContainer);

    this.openModal(backdrop);
  }

  ngOnDestroy(): void {
    window.removeEventListener('mousedown', this.removableBackdropClickHandler);
  }
}
