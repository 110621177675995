import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'bg-shapes',
  templateUrl: './bg-shapes.component.html',
  styleUrls: ['./bg-shapes.component.scss']
})
export class BgShapesComponent implements OnInit {

  constructor() {}
  removableAnimationHandler;

  ngOnInit(): void {
    this.animateCircles();
  }

  animateCircles(): void {

    var tick = false;
    window.addEventListener('scroll', this.removableAnimationHandler = function () {

      
      if (!tick) {
        let circles = Array.from(document.getElementsByClassName('circles') as HTMLCollectionOf < HTMLElement > )
        var verticalPosition = 0;
        setTimeout(function () {
          scrollAnimationsHandler(verticalPosition, circles);
          tick = false;
        }, 16)
      }
      tick = true;
    }, {
      passive: true
    });

    function scrollAnimationsHandler(verticalPosition, circles): void {
      if (pageYOffset) //usual
        verticalPosition = pageYOffset;
      else if (document.documentElement.clientHeight) //ie
        verticalPosition = document.documentElement.scrollTop;
      else if (document.body) //ie quirks
        verticalPosition = document.body.scrollTop;
      else {
        // mobile ?
        verticalPosition = window.scrollY;
      }

      circles[0].style.transform = "translateY(" + (-verticalPosition * 0.04) + "px)";
    }
  }


  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.removableAnimationHandler);
  }
}
