<!-- <div id="footer" [ngClass]="currentTheme"> -->
<div id="footer" class="">
  <div class="footer">
    <div class="footer-bg container">
      <div class="pb-5 pt-5 pl-2 pl-sm-0 pr-2 pr-sm-0">
        <div class="row contact-form">
          <div class="col-lg-5">
            <div class="d-flex justify-content-center flex-direction-column">
              <div class="subtitle text-white mb-3">Wanna work with me?</div>
              <a class="mr-lg-20" href="/i-want-project" tabindex="-1">
                <button
                  mat-button
                  class="primary-gradient-footer-button-on-dark bg-color-white footer-project-request-button"
                  tabindex="0"
                >
                  Request project quote 🚀
                </button>
              </a>
            </div>

            <div class="subtitle text-white mb-3 mt-5">
              Or send me a message
            </div>
            <form
              [formGroup]="messageModel"
              (ngSubmit)="onFormSubmit(messageModel, formDirective)"
              #formDirective="ngForm"
            >
              <div class="row">
                <mat-form-field class="col pr-lg-3 pr-xl-0" appearance="fill">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    placeholder="Ex. Michael Jackson"
                    formControlName="name"
                  />
                </mat-form-field>

                <mat-form-field class="col" appearance="fill">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Ex. hey.there@you.com"
                    formControlName="email"
                  />
                </mat-form-field>
              </div>

              <!-- <mat-form-field class="w-100" appearance="fill">
                <mat-label>Subject</mat-label>
                <input matInput placeholder="Ex. San Francisco" formControlName="subject">
              </mat-form-field> -->

              <mat-form-field class="w-100" appearance="fill">
                <mat-label>How can I help?</mat-label>
                <textarea
                  formControlName="message"
                  matInput
                  placeholder="Ex. Hello, I would love to work with you"
                  rows="5"
                ></textarea>
              </mat-form-field>

              <button
                mat-button
                type="submit"
                class="primary-gradient-footer-button-on-dark bg-color-white footer-message-button"
                tabindex="0"
              >
                {{ actionButtonLabel }}
              </button>
              <!-- <button type="submit" mat-button
                class="color-primary bg-color-white footer-action-button w-100">{{actionButtonLabel}}</button> -->
            </form>
          </div>
          <div
            class="col-lg-7 justify-content-center align-items-center mt-4 mb-4 d-flex flex-column"
          >
            <!-- <div class="row mb-5 justify-content-center">
              <button mat-button class="color-primary bg-color-white footer-action-button">Request a meeting</button>
            </div> -->
            <div class="row subtitle justify-content-center">Find me here</div>
            <div class="row justify-content-center gap-2">
              <a
                href="mailto:hi@eduard.digital"
                target="_blank"
                rel="noopener"
                aria-label="hi@eduard.digital"
              >
                <img
                  alt="Gmail"
                  title="Gmail"
                  class="social-icon"
                  src="/assets/icons/google.svg"
                />
              </a>
              <a
                href="https://github.com/Edduard"
                target="_blank"
                rel="noopener"
                aria-label="github.com/Edduard"
              >
                <img
                  alt="Github"
                  title="Github"
                  class="social-icon"
                  src="/assets/icons/github.svg"
                />
              </a>
              <a
                href="https://instagram.com/eduard.digital_"
                target="_blank"
                rel="noopener"
                aria-label="instagram.com/eduard.digital_"
              >
                <img
                  alt="Instagram"
                  title="Instagram"
                  class="social-icon"
                  src="/assets/icons/instagram.svg"
                />
              </a>
              <!-- <a href="https://tiktok.com/eduard.digital_" target="_blank" rel="noopener" aria-label="tiktok.com/eduard.digital_">
                <img alt="Tiktok" title="Tiktok" class="social-icon" src="/assets/icons/tiktok.png" />
              </a> -->
              <!-- <a href="https://dribbble.com/spedst" target="_blank" rel="noopener" aria-label="dribbble.com/spedst">
                <img alt="Dribbble" title="Dribbble" class="social-icon" src="/assets/icons/dribbble.svg" />
              </a> -->
              <!-- <a href="https://www.fiverr.com/eduardlache?up_rollout=true" target="_blank" rel="noopener" aria-label="fiverr.com/eduardlache">
                <img alt="Fiverr" title="Fiverr" class="social-icon" src="/assets/icons/fiverr.png" />
              </a> -->
              <a
                href="https://www.freelancer.com/u/eduardlache"
                target="_blank"
                rel="noopener"
                aria-label="freelancer.com"
              >
                <img
                  alt="Freelancer"
                  title="Freelancer"
                  class="social-icon"
                  src="/assets/icons/freelancer.png"
                />
              </a>
              <a
                href="https://www.upwork.com/freelancers/~0115fd799453784ebc?viewMode=1"
                target="_blank"
                rel="noopener"
                aria-label="upwork.com"
              >
                <img
                  alt="UpWork"
                  title="UpWork"
                  class="social-icon"
                  src="/assets/icons/upwork.png"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/eduard-lache-6ba588b1/"
                target="_blank"
                rel="noopener"
                aria-label="linkedin.com/in/eduard-lache-6ba588b1"
              >
                <img
                  alt="Linkedin"
                  title="Linkedin"
                  class="social-icon"
                  src="/assets/icons/linkedin.svg"
                />
              </a>
              <!-- <img alt="Facebook" title="Facebook" class="social-icon" src="/assets/icons/facebook.png" /> -->
            </div>
            <img
              height="auto"
              width="280px"
              class="mt-3"
              src="./../../../assets/logos/new-logo-blue.webp"
            />
          </div>
        </div>

        <div class="copyright mt-5 text-center">
          <!-- Designed in Figma and handcrafted with code. -->
          Designed in Figma and coded with Angular.
          <br />
          All rights reserved Eduard {{ currentYear }}.
        </div>
      </div>
    </div>
  </div>
</div>
