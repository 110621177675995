import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
})
export class ActionCardComponent implements OnInit {
  @Input() projects;
  showLeftScrollControl = false;
  showRightScrollControl = true;
  scrollableArea;
  shouldScroll = true;
  removableBackdropClickHandler;
  backdrop;
  iframeData = {
    iframeURL: 'https://eduard-form.web.app/',
    iframeParameters: {
      width: { amount: 65, units: 'vw' },
      height: { amount: 90, units: 'vh' },
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.backdrop = document.getElementById('global-backdrop');
    this.backdrop.addEventListener(
      'mousedown',
      (this.removableBackdropClickHandler = function () {
        const globalBackdrop = document.getElementById('global-backdrop');
        globalBackdrop.classList.remove('active-backdrop');
        // This removes all children and only keeps the first one (the close button)
        // @ts-ignore
        globalBackdrop.replaceChildren(globalBackdrop.firstChild);
      }),
      {
        passive: true,
      }
    );
  }

  closeModal() {
    this.backdrop.classList.remove('active-backdrop');
    // This removes all children and only keeps the first one (the close button)
    // @ts-ignore
    this.backdrop.replaceChildren(this.backdrop.firstChild);
  }

  openModal() {
    this.backdrop.classList.add('active-backdrop');
  }

  openContactForm(): void {
    let iframeContainer = document.createElement('div');
    iframeContainer.classList.add('preview-prototype-iframe-container');
    iframeContainer.style.width =
      this.iframeData.iframeParameters.width.amount +
      this.iframeData.iframeParameters.width.units;
    iframeContainer.style.height =
      this.iframeData.iframeParameters.height.amount +
      this.iframeData.iframeParameters.height.units;
    iframeContainer.style.minWidth = '300px';
    iframeContainer.style.maxWidth = '95vw';
    iframeContainer.style.maxHeight = '95vh';
    iframeContainer.style.minHeight = `500px`;
    iframeContainer.style.border = '3px solid #343a40';
    iframeContainer.style.backgroundColor = 'black';
    iframeContainer.style.position = 'absolute';
    iframeContainer.style.top = '50%';
    iframeContainer.style.left = '50%';
    iframeContainer.style.transform = 'translate(-50%, -50%)';
    iframeContainer.style.borderRadius = '32px';
    iframeContainer.style.overflow = 'hidden';

    let iframe = document.createElement('iframe');
    iframe.src = this.iframeData.iframeURL;
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.style.border = 'none';

    iframeContainer.appendChild(iframe);
    this.backdrop.appendChild(iframeContainer);

    this.openModal();
  }

  ngOnDestroy(): void {
    window.removeEventListener('mousedown', this.removableBackdropClickHandler);
  }
}
