<div class="container">
    <div class="d-flex justify-content-between mb-5 row">

      <div class="project-navigator-container animate-on-hover col-12 col-md-6 col-xl-4" (click)="goPrevious()">
        <div class="d-flex justify-content-start">
          <icon-long-arrow-back></icon-long-arrow-back>
          <div class="subheading pl-4 pb-4 pt-4">
            Previous project
          </div>
        </div>
        <div class="glass-card project-navigator-card" tabindex=0>
          <div class="mb-4 d-flex justify-content-between align-items-center">
            <div class="subheading">
              {{projects[previousProject].title}}
            </div>
            <img loading="lazy" [alt]="projects[previousProject].title" [title]="projects[previousProject].title" height="48px" [src]="'./../../.'+ projects[previousProject].logoPath" />
          </div>
          <div>
            <span class="subheading">Role: </span><span class="text-body">{{projects[previousProject].role}}</span>
          </div>
          <div>
            <span class="subheading">Technologies: </span><span class="text-body">{{projects[previousProject].technologies}}</span>
          </div>
        </div>
      </div>
    
      <div class="project-navigator-container animate-on-hover col-12 col-md-6 col-xl-4" (click)="goNext()">
        <div class="d-flex justify-content-end">
          <div class="subheading pr-4 pb-4 pt-4">
            Next project
          </div>
          <div style="transform: rotate(180deg);">
            <icon-long-arrow-back></icon-long-arrow-back>
          </div>
        </div>
        <div class="glass-card project-navigator-card" tabindex=0>
          <div class="mb-4 d-flex justify-content-between align-items-center">
            <div class="subheading">
              {{projects[nextProject].title}}
            </div>
            <img loading="lazy" height="48px" [alt]="projects[nextProject].title" [title]="projects[nextProject].title" [src]="'./../../.'+ projects[nextProject].logoPath" />
          </div>
    
          <div>
            <span class="subheading">Role: </span><span class="text-body">{{projects[nextProject].role}}</span>
          </div>
          <div>
            <span class="subheading">Technologies: </span><span class="text-body">{{projects[nextProject].technologies}}</span>
          </div>
        </div>
      </div>
    </div>
</div>
