import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-write-envelope',
  templateUrl: './write-envelope.component.html',
  styleUrls: ['./write-envelope.component.scss']
})
export class WriteEnvelopeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
