import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
